import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminEditRequestConstants } from "../../constants";
import AdminRequestService from "../../../../../services/admin/adminRequestService";
import RequestStatusTypeService from "../../../../../services/metadata/requestStatusTypesService";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";

export const getDocumentRequestStatusHistory = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestStatusHistory`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug(
        "Get Document Request Status History Thunk Payload",
        payload
      );
      return await AdminRequestService.getDocumentRequestStatusHistory(payload);
    } catch (error) {
      console.error(
        "Failed to get admin document request status history",
        error
      );
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const getRequestStatusTypes = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getRequestStatusTypes`,
  async (_, { rejectWithValue }) => {
    try {
      return await RequestStatusTypeService.getRequestStatusTypes();
    } catch (error) {
      console.error("Failed to get request status types", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const updateStatusHistory = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/updateStatusHistory`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const documentRequestStatusHistoryApiPayload =
        state.adminEditRequest.apiPayload.documentRequestStatusHistory;
      return await AdminRequestService.updateDocumentRequestStatusHistory(
        payload,
        documentRequestStatusHistoryApiPayload
      );
    } catch (error) {
      console.error("Failed to get update Status History", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);