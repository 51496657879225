import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileInformationConstant } from "../../constants";
import NeweraRequestService from "services/newera/neweraRequestService";
import { buildErrorDisplayMessage } from "utils/errorMessageBuilder";

export const updateAttorneyOfficeDetails = createAsyncThunk(
  `${ProfileInformationConstant.StoreSliceName}/updateAttorneyOfficeDetails`,
  async (payload, {rejectWithValue }) => {
    try {
      const result =  await NeweraRequestService.updateAttorneyOfficeDetails(payload);
      return result
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
