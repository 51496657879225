import { initialState } from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { ProfileInformationConstant } from "../constants";
import { getUserInformation } from "./thunks/getUserInformationThunk";
import {
  getUserInformationComplete,
  getUserInformationFailure,
  getUserInformationPending,
} from "./reducers/getUserInformationReducer";
import { updateUserDetails } from "./thunks/updateUserDetailsThunk";
import { updateCompanyDetails } from "./thunks/updateCompanyDetailsThunk";
import {
  updateUserDetailsComplete,
  updateUserDetailsFailure,
  updateUserDetailsPending,
} from "./reducers/updateUserDetailsReducer";
import {
  updateCompanyDetailsFailure,
  updateCompanyDetailsPending,
  updateUserCompanyComplete,
} from "./reducers/updateCompanyDetailsReducer";
import { updateBackOfficeDetails } from "./thunks/updateBackOfficeDetailsThunk";
import { updateBackOfficeDetailsComplete, updateBackOfficeDetailsFailure, updateBackOfficeDetailsPending } from "./reducers/updateBackOfficeDetailsReducer";
import { updateInsuranceCompanyDetails } from "./thunks/updateInsuranceCompanyDetailsThunk";
import { updateInsuranceCompanyDetailsComplete, updateInsuranceCompanyDetailsFailure, updateInsuranceCompanyDetailsPending } from "./reducers/updateInsuranceCompanyDetailsReducer";
import { updateAttorneyOfficeDetails } from "./thunks/updateAttorneyOfficeDetailsThunk";
import { updateAttorneyOfficeDetailsComplete, updateAttorneyOfficeDetailsFailure, updateAttorneyOfficeDetailsPending } from "./reducers/updateAttorneyOfficeDetailsReducer";
import {updateDoctorFacilityDetails} from "./thunks/updateDoctorFacilityDetailsThunk";
import {updateDoctorFacilityDetailsComplete, updateDoctorFacilityDetailsFailure, updateDoctorFacilityDetailsPending} from "./reducers/updateDoctorFacilityDetailsReducer";

const slice = createSlice({
  name: ProfileInformationConstant.StoreSliceName,
  initialState,
  reducers: {
    setServerResponse : (state, action) =>{
      state.serverResponse = action.payload
    },
    setUserInformation: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInformation.pending, getUserInformationPending)
      .addCase(getUserInformation.fulfilled, getUserInformationComplete)
      .addCase(getUserInformation.rejected, getUserInformationFailure)
     
      .addCase(updateUserDetails.pending, updateUserDetailsPending)
      .addCase(updateUserDetails.fulfilled, updateUserDetailsComplete)
      .addCase(updateUserDetails.rejected, updateUserDetailsFailure)
     
      .addCase(updateCompanyDetails.pending, updateCompanyDetailsPending)
      .addCase(updateCompanyDetails.fulfilled, updateUserCompanyComplete)
      .addCase(updateCompanyDetails.rejected, updateCompanyDetailsFailure)

      .addCase(updateBackOfficeDetails.pending, updateBackOfficeDetailsPending)
      .addCase(updateBackOfficeDetails.fulfilled, updateBackOfficeDetailsComplete)
      .addCase(updateBackOfficeDetails.rejected, updateBackOfficeDetailsFailure)

      .addCase(updateInsuranceCompanyDetails.pending, updateInsuranceCompanyDetailsPending)
      .addCase(updateInsuranceCompanyDetails.fulfilled, updateInsuranceCompanyDetailsComplete)
      .addCase(updateInsuranceCompanyDetails.rejected, updateInsuranceCompanyDetailsFailure)

      .addCase(updateAttorneyOfficeDetails.pending, updateAttorneyOfficeDetailsPending)
      .addCase(updateAttorneyOfficeDetails.fulfilled, updateAttorneyOfficeDetailsComplete)
      .addCase(updateAttorneyOfficeDetails.rejected, updateAttorneyOfficeDetailsFailure)

      .addCase(updateDoctorFacilityDetails.pending, updateDoctorFacilityDetailsPending)
      .addCase(updateDoctorFacilityDetails.fulfilled, updateDoctorFacilityDetailsComplete)
      .addCase(updateDoctorFacilityDetails.rejected, updateDoctorFacilityDetailsFailure)
  },
});

export { getUserInformation, updateUserDetails, updateCompanyDetails, updateBackOfficeDetails, updateInsuranceCompanyDetails, updateAttorneyOfficeDetails, updateDoctorFacilityDetails };
export const {setServerResponse, setUserInformation } = slice.actions;
export default slice.reducer;
