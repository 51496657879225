import { initialState } from "./state";
import { createSlice } from "@reduxjs/toolkit";

import { UserRegistrationFormConstants } from "../constants";

const slice = createSlice({
  name: UserRegistrationFormConstants.StoreSliceName,
  initialState,
  reducers: {
    setRegisterationData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setUserType:(state, action) => {
      state.formData.userType = action.payload
    },
    resetRegisterationData:(state,payload)=>{
      state.formData = Object.assign({},initialState.formData);
    }
  },
  // extraReducers: (builder) => {
  //     builder
  //         .addCase(getMetadata.fulfilled, addMetadata)
  //         .addCase(getMetadata.rejected, failMetadata)
  //         .addCase(submitForm.pending, pendingFormSubmission)
  //         .addCase(submitForm.fulfilled, completeFormSubmission)
  //         .addCase(submitForm.rejected, failFormSubmission)
  // }
});

export const { setRegisterationData, setUserType,resetRegisterationData } = slice.actions;
export default slice.reducer;
