export const getAllUsersListPending = (state, action) => {
    console.debug('Get All User List Reducer Pending', action);
    state.error.getAllUsersList = null;
    state.userList = [];
    state.loading.getAllUsersList = true;
};

export const getAllUsersListComplete = (state, action) => {
    console.debug('Get All User List Reducer Complete', action);
    state.error.getUserDetails = null;
    state.userList = action.payload;
    state.loading.getAllUsersList = false;
};

export const getAllUsersListFailure = (state, action) => {
    console.debug('Get All User List Reducer Failure', action);
    state.error.getUserDetails = action.payload;
    state.loading.getAllUsersList = false;
    state.userList = []
};
