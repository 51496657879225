export const getUserInformationPending = (state, action) => {
    console.debug('Document Request Details Reducer Pending', action);
    state.error.getUserDetails = null;
    state.userData = {};
    state.loading.getUserDetails = true;
    state.serverResponse = null
};

export const getUserInformationComplete = (state, action) => {
    console.debug('Document Request Details Reducer Complete', action);
    localStorage.setItem('userEmail', JSON.stringify(action.payload.email))
    state.error.getUserDetails = null;
    state.userData = action.payload;
    state.loading.getUserDetails = false;
    state.serverResponse = null

};

export const getUserInformationFailure = (state, action) => {
    console.debug('Document Request Details Reducer Failure', action);
    state.error.getUserDetails = action.payload;
    state.loading.getUserDetails = false;
    state.serverResponse = null
};
