import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CompanyListConstant,
  DoctorFacilityListConstant,
} from "../../constants";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";
import NeweraRequestService from "services/newera/neweraRequestService";

export const addCompany = createAsyncThunk(
  `${CompanyListConstant.StoreSliceName}/addCompany`,
  async (payload, { rejectWithValue }) => {
    const { resetForm, ...bodyData } = payload;
    try {
      console.debug("Add Company", payload);
      const result = await NeweraRequestService.addCompany(bodyData);
      if (result && result.status) {
        resetForm();
      }
      return result;
    } catch (error) {
      console.error("Add Company", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
