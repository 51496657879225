export const initialState = {
  data: [
    {
      firstName: "",
      lastName: "",
      dob: "",
      joinDate: "",
      userType: "",
      phoneNumber: "",
      email: "",
      addressLine1:"",
      addressLine2:"",
      state: "",
      city: "",
      zipcode: "",
      isActive: "",
      // address:{
      //   line1: "",
      //   line2: "",
      //   state: "",
      //   zipcode: "",
      //   city: ""
      // },
    
    }
  ]
   

  ,
  addCompany: {
    firstName: "",
    lastName: "",
    dob: "",
    joinDate: "",
    role: "",
    phoneNumber: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    zipcode: "",
    city: "",
    isActive: ""
  },
  loading: {
    addCompany:false,
    updateCompanyUserDetails: false,
    getCompanyList:false
  },
  error: {
    addCompany:null,
    updateCompanyUserDetails:null,
    getCompanyList: false
  },
  serverResponse: null,
};