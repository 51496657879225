export const getCompanyListPending = (state, action) => {
  console.debug("Company List Reducer Pending", action);
  state.error.companyList = null;
  state.data = [];
  state.loading.companyList = true;
  state.serverResponse = null;
};

export const getCompanyListComplete = (state, action) => {
  console.debug("Company List Reducer Complete", action);
  state.error.companyList = null;
  state.data = action.payload;
  state.loading.companyList = false;
  state.serverResponse = null;
};

export const getCompanyListFailure = (state, action) => {
  console.debug("Company List Reducer Failure", action);
  state.error.companyList = action.payload;
  state.loading.companyList = false;
  state.serverResponse = null;
};

// single company details

export const getCompanyDetailsPending = (state, action) => {
  console.debug("Company Details Reducer Pending", action);
  state.error.companyDetails = null;
  state.singleCompanyData = [];
  state.loading.companyDetails = true;
  state.serverResponse = null;
};

export const getCompanyDetailsComplete = (state, action) => {
  console.debug("Company Details Reducer Complete", action);
  state.error.companyDetails = null;
  state.singleCompanyData = action.payload;
  state.loading.companyDetails = false;
  state.serverResponse = null;
};

export const getCompanyDetailsFailure = (state, action) => {
  console.debug("Company Details Reducer Failure", action);
  state.error.singleCompanyData = action.payload;
  state.loading.companyDetails = false;
  state.serverResponse = null;
};
