import React, { useState } from "react";
import { Provider, useDispatch } from "react-redux";
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
  useNavigate,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import "./locales";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "components/ui";
import { setPasswordResetFields } from "views/auth/ForgotPassword/store";
import { apiForgotPassword } from "services/AuthService";
import StatusIcon from "components/ui/StatusIcon";
import { onSignOutSuccess } from "store/auth/sessionSlice";
import { userLoggedOut } from "store/auth/userSlice";
import { setUserInformation } from "views/patient/profile/store";
import dayjs from "dayjs";
import { HiBellAlert } from "react-icons/hi2";
import { ErrorBoundary } from "components/shared/ErrorBoundary";
import { Worker } from '@react-pdf-viewer/core';


const environment = process.env.NODE_ENV;

export const AlertForResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDate = dayjs();
  const days = localStorage.getItem("remainDays");
  const isPasswordExpiryIn2Days = localStorage.getItem("remainDays")
    ? JSON.parse(localStorage.getItem("remainDays"))
    : false;

  const [isSubmitting, setSubmitting] = useState(false);

  const onSendMail = async (values) => {
    const bodyData = { userName: values.userName };
    setSubmitting(true);
    dispatch(setPasswordResetFields({ email: values.userName }));
    try {
      const resp = await apiForgotPassword(bodyData);
      if (resp.status === 200) {
        setSubmitting(false);
        navigate("/reset-password");
      }
    } catch (errors) {
      setSubmitting(false);
    }
  };

  const handleClose = async (e) => {
    e.preventDefault();
    const user = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : {};
    localStorage.clear();
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
    dispatch(setUserInformation());
    dispatch({ type: "reset" });
    await onSendMail({ userName: user.userName });
  };

  if (!isPasswordExpiryIn2Days) {
    return null;
  }

  return (
    <div className="p-4 relative flex font-semibold gap-3 items-center">
      <div className="flex items-center gap-1">
        <StatusIcon
          iconColor="text-[#9CA3AF] dark:text-[#9CA3AF]"
          custom={<HiBellAlert />}
          type="info"
        />
        <div className={`font-semibold mb-1`}>
          Password expiring on{" "}
          {currentDate.add(Number(days), "days").format("DD MMM, YYYY")}.
        </div>
      </div>
      <a
        disable={isSubmitting}
        className="underline underline-offset-4 text-slate-300 mb-[3px]"
        href="/"
        onClick={handleClose}
      >
        Change Now
      </a>
    </div>
  );
};

const AppProvider = () => {  
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Theme>
              <Layout />
            </Theme>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </Worker>
  );
};

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route path="*" element={<AppProvider />} />
      </React.Fragment>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
