import { DocumentRequestFormConstants } from "views/documentsRequest/constants";
import { logDebug, logError } from "../../../../../services/telemetry/logger";

export const addRequestStatusReducer = (state, action) => {
  const { statusId } = action.payload;
  const requestStatusType = state.metadata.requestStatusTypes.find(
    (type) => type.id === statusId
  );
  if (requestStatusType == null) {
    logError(
      "Invalid request status cannot be added to status history",
      "RequestStatusHistoryReducer"
    );
    return;
  }
  const newStatusEntry = {
    id: _generateRandomUniqueId(state.documentRequest.statusHistory),
    documentRequestOrderId: state.documentRequest.id,
    statusId: statusId,
    updatedBy: "UI_USER", // This name must come from state, when user is logged in
    updatedAt: _getFormattedCurrentDate(),
    requeststatus: {
      id: statusId,
      description: requestStatusType.description,
    },
  };
  logDebug(
    `RequestStatusHistoryReducer: Adding new Status Entry: ${JSON.stringify(
      newStatusEntry
    )}`,
    "RequestStatusHistoryReducer"
  );
  state.documentRequest = {
    ...state.documentRequest,
    statusHistory: [...state.documentRequest.statusHistory, newStatusEntry],
  };

  // Add the entry in API payload
  if (state.apiPayload.documentRequestStatusHistory == null) {
    state.apiPayload.documentRequestStatusHistory = [];
  }
  state.apiPayload.documentRequestStatusHistory.push({
    ...newStatusEntry,
    _action: DocumentRequestFormConstants.ApiActions.Add,
  });
  state.modifications.statusHistory = true;
};

export const editRequestStatusReducer = (state, action) => {
  const { statusId } = action.payload;
  const requestStatusType = state.metadata.requestStatusTypes.find(
    (type) => type.id === statusId
  );
  if (requestStatusType == null) {
    logError(
      "Invalid request status cannot be added to status history",
      "RequestStatusHistoryReducer"
    );
    return;
  }

  const documentRequestStatusHistoryId =
    state.ui.editableDocumentRequestStatusHistoryId;
  const statusHistoryEntryIndex = state.documentRequest.statusHistory.findIndex(
    (status) => status.id === documentRequestStatusHistoryId
  );
  if (statusHistoryEntryIndex == null || statusHistoryEntryIndex < 0) {
    logError(
      "Invalid request status cannot be added to status history",
      "RequestStatusHistoryReducer"
    );
    return;
  }
  logDebug(
    `Editing status history index: ${statusHistoryEntryIndex}`,
    "RequestStatusHistoryReducer"
  );

  const updatedStatusEntry = {
    ...state.documentRequest.statusHistory[statusHistoryEntryIndex],
    documentRequestOrderId: state.documentRequest.id,
    statusId: statusId,
    updatedBy: "UI_USER", // This name must come from state, when user is logged in
    updatedAt: _getFormattedCurrentDate(),
    requeststatus: {
      id: statusId,
      description: requestStatusType.description,
    },
  };
  state.documentRequest.statusHistory[statusHistoryEntryIndex] =
    updatedStatusEntry;
  if (state.apiPayload.documentRequestStatusHistory == null) {
    state.apiPayload.documentRequestStatusHistory = [];
  }

  // Add the entry in API payload
  const updatedStatusHistoryApiPayloadEntity = {
    ...updatedStatusEntry,
    _action: DocumentRequestFormConstants.ApiActions.Update,
  };
  const statusHistoryApiPayloadIndex =
    state.apiPayload.documentRequestStatusHistory.findIndex(
      (statusHistory) => statusHistory.id === documentRequestStatusHistoryId
    );
  // Checking if the status history entity has already been addded to the payload (edited or added in the same session)
  if (statusHistoryApiPayloadIndex >= 0) {
    state.apiPayload.documentRequestStatusHistory[
      statusHistoryApiPayloadIndex
    ] = updatedStatusHistoryApiPayloadEntity;
  } else {
    state.apiPayload.documentRequestStatusHistory.push(
      updatedStatusHistoryApiPayloadEntity
    );
  }
  state.modifications.statusHistory = true;
};

export const deleteRequestStatusReducer = (state, action) => {
  const { documentRequestStatusHistoryId } = action.payload;
  state.documentRequest = {
    ...state.documentRequest,
    statusHistory: state.documentRequest.statusHistory.filter(
      (entry) => entry.id !== documentRequestStatusHistoryId
    ),
  };

  const statusHistoryApiPayloadIndex =
    state.apiPayload.documentRequestStatusHistory.findIndex(
      (statusHistory) => statusHistory.id === documentRequestStatusHistoryId
    );
  // Checking if the status history entity has already been added to the payload (edited or added in the same session)
  if (statusHistoryApiPayloadIndex >= 0) {
    // The entry has been added in this session, so deletion means simply removal of the entry from the payload
    if (
      state.apiPayload.documentRequestStatusHistory[
        statusHistoryApiPayloadIndex
      ]._action === DocumentRequestFormConstants.ApiActions.Add
    ) {
      const newlyAddedStatusHistoryId =
        state.apiPayload.documentRequestStatusHistory[
          statusHistoryApiPayloadIndex
        ].id;
      state.apiPayload.documentRequestStatusHistory =
        state.apiPayload.documentRequestStatusHistory.filter(
          (statusHistory) => statusHistory.id !== newlyAddedStatusHistoryId
        );
    } else {
      state.apiPayload.documentRequestStatusHistory[
        statusHistoryApiPayloadIndex
      ]._action = DocumentRequestFormConstants.ApiActions.Delete;
    }
  } else {
    state.apiPayload.documentRequestStatusHistory.push({
      id: documentRequestStatusHistoryId,
      _action: DocumentRequestFormConstants.ApiActions.Delete,
    });
  }
  state.modifications.statusHistory = true;
};

export const getDocumentRequestStatusHistoryPending = (state, action) => {
  console.debug("Document Request Status History Reducer Pending", action);
  state.errors.statusHistory = null;
  if (state.documentRequest != null) {
    state.documentRequest.statusHistory = [];
  }
  state.loading.statusHistory = true;
};

export const getDocumentRequestStatusHistoryComplete = (state, action) => {
  console.debug("Document Request Status History Reducer Complete", action);
  state.errors.statusHistory = null;
  if (state.documentRequest == null) {
    state.documentRequest = {};
  }
  state.documentRequest.statusHistory = action.payload;
  state.loading.statusHistory = false;
};

export const getDocumentRequestStatusHistoryFailure = (state, action) => {
  console.debug("Document Request Status History Reducer Failure", action);
  state.errors.statusHistory = action.payload;
  state.loading.statusHistory = false;
};

export const getRequestStatusTypesPending = (state, action) => {
  console.debug("Request Status Types Reducer Pending", action);
  state.errors.metadata.requestStatusTypes = null;
  state.metadata.requestStatusTypes = [];
  state.loading.metadata.requestStatusTypes = true;
};

export const getRequestStatusTypesComplete = (state, action) => {
  console.debug("Request Status Types Reducer Complete", action);
  state.errors.metadata.requestStatusTypes = null;
  state.metadata.requestStatusTypes = action.payload.map((statusType) => {
    return {
      ...statusType,
      value: statusType.id,
      label: statusType.description,
    };
  });
  state.loading.metadata.requestStatusTypes = false;
};

export const getRequestStatusTypesFailure = (state, action) => {
  console.debug("Request Status Types Reducer Failure", action);
  state.metadata.requestStatusTypes = [];
  state.errors.metadata.requestStatusTypes = action.payload;
  state.loading.metadata.requestStatusTypes = false;
};

export const updateStatusHistoryPending = (state, action) => {
  console.debug("Update Status History Reducer Pending", action);
  state.errors.updates.statusHistory = null;
  state.loading.updates.statusHistory = true;
};

export const updateStatusHistoryFailure = (state, action) => {
  console.debug("Update Status History Reducer Failure", action);
  state.errors.updates.statusHistory = action.payload;
  state.loading.updates.statusHistory = false;
};

export const updateStatusHistoryComplete = (state, action) => {
  console.debug("Update Status History Reducer Complete", action);
  state.apiPayload.documentRequestStatusHistory = [];
  state.errors.updates.statusHistory = null;
  state.loading.updates.statusHistory = false;
  state.modifications.statusHistory = false;
};

export const resetStatusHistoryApiPayloadReducer = (state, _) => {
  console.debug("Reset Status History Api Payload");
  state.apiPayload.documentRequestStatusHistory = [];
};

const _generateRandomUniqueId = (documentRequestStatusHistory) => {
  const maxId =
    documentRequestStatusHistory && documentRequestStatusHistory.length
      ? Math.max(...documentRequestStatusHistory.map((entry) => entry.id))
      : 0;
  const randomNumber = Number.parseInt(Math.random() * 100, 10);
  logDebug(
    `Generating random document request status history maxId: ${maxId} | randomNumber - ${randomNumber}`
  );
  return maxId + randomNumber;
};

const _getFormattedCurrentDate = () => {
  const now = new Date();
  return `${now.getUTCFullYear()}-${now.getUTCMonth()}-${now.getUTCDate()}T${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}.${now.getUTCMilliseconds()}Z`;
};
