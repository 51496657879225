export const addCompanyPending = (state, action) => {
  console.debug("Add Company Reducer Pending", action);
  state.error.addCompany = null;
  state.loading.addCompany = true;
  state.serverResponse = null;
};

export const addCompanyComplete = (state, action) => {
  console.debug("Add Company Reducer Complete", action);
  state.error.addCompany = null;
  state.loading.addCompany = false;
  state.serverResponse = action.payload;
};

export const addCompanyFailure = (state, action) => {
  console.debug("Add Company Reducer Failure", action);
  state.error.addCompany = action.payload;
  state.loading.addCompany = false;
  state.serverResponse = action.payload;
};

export const updateCompanyUserDetailsPending = (state, action) => {
  console.debug("updateCompanyUserDetailsPending Reducer Pending", action);
  state.error.updateCompanyUserDetails = true;
  state.loading.updateCompanyUserDetails = true;
  state.serverResponse = null;
};

export const updateCompanyUserDetailsComplete = (state, action) => {
  console.debug("updateCompanyUserDetailsPending Reducer Complete", action);
  state.error.updateCompanyUserDetails = false;
  state.loading.updateCompanyUserDetails = false;
  state.serverResponse = action.payload;
};

export const updateCompanyUserDetailsFailure = (state, action) => {
  console.debug("updateCompanyUserDetailsPending Reducer Failure", action);
  state.error.updateCompanyUserDetails = action.payload;
  state.loading.updateCompanyUserDetails = false;
  state.serverResponse = action.payload;
};



