export const updateBackOfficeDetailsPending = (state, action) => {
    console.debug('Update Back Office Reducer Pending', action);
    state.error.updateBackOfficeDetails = null;
    state.loading.updateBackOfficeDetails = true;
    state.serverResponse = null;
};

export const updateBackOfficeDetailsComplete = (state, action) => {
    console.debug('Update Back Office Reducer Complete', action);
    state.error.updateBackOfficeDetails = null;
    state.loading.updateBackOfficeDetails = false;
    state.serverResponse = action.payload;

};

export const updateBackOfficeDetailsFailure = (state, action) => {
    console.debug('Update Back Office Reducer Failure', action);
    state.error.updateBackOfficeDetails = action.payload;
    state.loading.updateBackOfficeDetails = false;
    state.serverResponse = action.payload;
};
