export const updateUserDetailsPending = (state, action) => {
    console.debug('Update user details pending', action);
    state.error.updateUserDetails = null;
    state.loading.updateUserDetails = true;
    state.serverResponse = null
};

export const updateUserDetailsComplete = (state, action) => {
    console.debug('Update user details completed', action.payload);
    state.error.updateUserDetails = null;
    state.loading.updateUserDetails = false;
    state.serverResponse = action.payload;
};

export const updateUserDetailsFailure = (state, action) => {
    console.debug('Update user details pending Failure', action.payload);
    // state.error.updateUserDetails = action.payload;
    state.loading.updateUserDetails = false;
    state.serverResponse = action.payload;
};
