import { initialState } from './state';
import { createSlice } from '@reduxjs/toolkit'
import { AdminUserListConstants } from '../constants';
import { getAllUsersList } from './thunks/getAllUsersListThunk';
import { getAllUsersListComplete, getAllUsersListFailure, getAllUsersListPending } from './reducers/getAllUsersListReducer';


const slice = createSlice({
    name: AdminUserListConstants.StoreSliceName,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsersList.pending, getAllUsersListPending)
            .addCase(getAllUsersList.fulfilled, getAllUsersListComplete)
            .addCase(getAllUsersList.rejected, getAllUsersListFailure)
    }
});

export {getAllUsersList}

export default slice.reducer;