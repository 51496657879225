export const addMetadata = (state, action) => {
    const {
        recordTypes,
        requestTypes,
        doctorFacilities,
        govertmentIdTypes,
    } = action.payload;
   state.metadata = {
        ...state.metadata,
        doctorFacilities: doctorFacilities.map(doctorFacility => {
            return {
                value: doctorFacility.id,
                label: doctorFacility.facilityName,
                isActive: doctorFacility.isActive,
                phone: doctorFacility.phoneNumber,
                email: doctorFacility.email,
                address: doctorFacility.address ?? null,
                fax: doctorFacility.faxNumber,
                recordTypes: doctorFacility?.recordTypes,
                orgId: doctorFacility?.orgId
            };
        }),
        requestTypes: requestTypes.map(requestType => {
            return {
                value: requestType.id,
                label: requestType.description
            }
        }),
        recordTypes: recordTypes.map(recordType => {
            return {
                value: recordType.id,
                label: recordType.Description,
                description: `${recordType.Description}`
            }
        }),
        govertmentIdTypes: govertmentIdTypes.map(govertmentIdType => {
            return {
                value: govertmentIdType.Id,
                label: govertmentIdType.Description
            }
        }),
        isMetadataLoading: false,
        isMetadataAvailable: true,
        error: null
    };
};

export const pendingMetadata = (state, action)=>{
    state.metadata.isMetadataLoading = true;
    state.metadata.isMetadataAvailable = false;
    state.metadata.error = null;
}

export const failMetadata = (state, action) => {
    state.metadata.isMetadataLoading = false;
    state.metadata.isMetadataAvailable = false;
    state.metadata.error = action.payload;
};
