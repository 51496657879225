import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminRequestListConstants } from "../../constants";
import AdminRequestService from "../../../../../services/admin/adminRequestService";

export const getRequests = createAsyncThunk(
  `${AdminRequestListConstants.StoreSliceName}/getRequests`,
  async (payload, { rejectWithValue }) => {
    try {
      let useOrgId = false;
      const userRole = JSON.parse(localStorage.getItem('userData'))?.rolesData[0];
      if (['BackOffice', 'AttorneyOffice','FacilityAdmin', 'InsuranceCompany',"OperationsManager","FacilityOperationsManager","FacilityDataProcessingExecutive", "FacilityDocumentScanningAgent", "BackOfficeManager", "BackOfficeProcessingExecutive", "BackOfficeAgent","DataProcessingExecutive", "DocumentScanningAgent"].includes(userRole)) {
        useOrgId= true;
      }
      // get role and pass
      return await AdminRequestService.getAllRequests({internalStatus:payload,useOrgId});
    } catch (error) {
      // TODO (patrick): Add logs here
      console.error("Failed to get admin requests");
      console.error(error);
      rejectWithValue("Failed to get admin requests");
    }
  }
);

export const getRequestsCountsByStatus = createAsyncThunk(
  `${AdminRequestListConstants.StoreSliceName}/getRequestsStatusCount`,
  async (_, { rejectWithValue }) => {
    try {
      const requests = await AdminRequestService.getRequestsCountsByStatus();
      return requests;
    } catch (error) {
      // TODO (patrick): Add logs here
      console.error("Failed to get admin requests count by status ");
      console.error(error);
      rejectWithValue("Failed to get admin requests count by status");
    }
  }
);

export const getAdminEntityCounts = createAsyncThunk(
  `${AdminRequestListConstants.StoreSliceName}/getAdminEntityCounts`,
  async (_, { rejectWithValue }) => {
    try {
      const requests = await AdminRequestService.getAdminEntityCounts();
      return requests;
    } catch (error) {
      console.error("Failed to get admin entity counts ");
      console.error(error);
      rejectWithValue("Failed to get admin entity counts");
    }
  }
);
