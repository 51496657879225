export const initialState = {
  orderListData: [
    {
      DocumentRequestOrderId: null,
      RequestDate: "",
      RequestedByUserId: null,
      RequestedByUser: "",
      DoctorFacilityName: "",
      RecordTypeName: "",
      RequestTypeName: "",
      DeliveryDueDate: "",
      RequestStatusName: "",
      RequestedBy: "",
      InternalRefNum: "",
      BilledAmount: null,
      PaidAmount: null,
      DueAmount: null,
      InvoiceStatus: null,
    },
  ],
  orderSummaryData: [],
  loading: {
    patient: false,
  },
  errors: {
    patient: false,
  },
};
