import auth from "./auth";
import base from "./base";
import theme from "./theme/themeSlice";
import { combineReducers } from "redux";
import dataRequestForm from "../views/documentsRequest/store";
import companyList from "../views/newera/companyList/store";
import adminRequestList from "../views/admin/requestList/store";
import adminEditRequest from "../views/admin/editRequest/store";
import adminUserList from "../views/newera/userList/store";
import userInformation from "../views/patient/profile/store";
import UserRegistrationForm from "../views/auth/SignUp/store";
import orderList from "../views/patient/orderList/store";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    dataRequestForm,
    adminRequestList,
    adminEditRequest,
    companyList,
    adminUserList,
    userInformation,
    UserRegistrationForm,
    orderList,
    ...asyncReducers,
  });

  if (action.type === "reset") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
