import { initialState } from './state';
import { createSlice } from '@reduxjs/toolkit'
import { getRequests, getRequestsCountsByStatus, getAdminEntityCounts } from './thunks/getRequests';
import { AdminRequestListConstants } from '../constants';
import { addRequests, filterRequests, failRequests, pendingRequests, requestStatusCountPending, requestStatusCountComplete, requestStatusCountFailed, getAdminEntityCountsPending, getAdminEntityCountsComplete, getAdminEntityCountsFailed } from './reducers/requestReducer';

const slice = createSlice({
    name: AdminRequestListConstants.StoreSliceName,
    initialState,
    reducers: {
        filterTable: (state, action) => {
            filterRequests(state, action);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRequests.fulfilled, addRequests)
            .addCase(getRequests.rejected, failRequests)
            .addCase(getRequests.pending, pendingRequests)
            .addCase(getRequestsCountsByStatus.pending, requestStatusCountPending)
            .addCase(getRequestsCountsByStatus.fulfilled, requestStatusCountComplete)
            .addCase(getRequestsCountsByStatus.rejected, requestStatusCountFailed)
            .addCase(getAdminEntityCounts.pending, getAdminEntityCountsPending)
            .addCase(getAdminEntityCounts.fulfilled, getAdminEntityCountsComplete)
            .addCase(getAdminEntityCounts.rejected, getAdminEntityCountsFailed)
    }
});

export { getRequests, getRequestsCountsByStatus, getAdminEntityCounts };
export const { filterTable } = slice.actions;
export default slice.reducer;
