export const updateInsuranceCompanyDetailsPending = (state, action) => {
    console.debug('Update Insurance Company Reducer Pending', action);
    state.error.updateInsuranceCompanyDetails = null;
    state.loading.updateInsuranceCompanyDetails = true;
    state.serverResponse = null
};

export const updateInsuranceCompanyDetailsComplete = (state, action) => {
    console.debug('Update Insurance Company Reducer Complete', action);
    state.error.updateInsuranceCompanyDetails = null;
    state.loading.updateInsuranceCompanyDetails = false;
    state.serverResponse = action.payload

};

export const updateInsuranceCompanyDetailsFailure = (state, action) => {
    console.debug('Update Insurance Company Reducer Failure', action);
    state.error.updateInsuranceCompanyDetails = action.payload;
    state.loading.updateInsuranceCompanyDetails = false;
    state.serverResponse = action.payload
};
