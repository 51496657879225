import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentRequestFormConstants } from '../../constants';
import {
    RecordTypeService,
    RequestTypeService,
    DoctorFacilityService,
    GovernmentIdTypeService
} from '../../../../services/metadata';

export const getMetadata = createAsyncThunk(`${DocumentRequestFormConstants.StoreSliceName}/getMetadata`, async (_, { rejectWithValue }) => {
    try {
        const metadataResults = await Promise.allSettled([
            RecordTypeService.getRecordTypes(),
            RequestTypeService.getRequestTypes(),
            DoctorFacilityService.getDoctorFacilities(),
            GovernmentIdTypeService.getGovertmentIdTypes()
        ]);
        if (metadataResults.every(result => result.status === 'fulfilled')) {
            const metadata = {
                recordTypes: metadataResults[0].value,
                requestTypes: metadataResults[1].value,
                doctorFacilities: metadataResults[2].value,
                govertmentIdTypes: metadataResults[3].value,
            };
            return metadata;
        };
        // TODO - Log the failed metadata api calls
        return rejectWithValue('Failed to get metadata for document request form');
    } catch (error) {
        // TODO - Log the error;
        console.error(error);
        return rejectWithValue(error.message);
    }
});