export const AdminRequestListConstants = {
    StoreSliceName: 'AdminRequestList'
};

export const InternalStatusEnum = {
    'All': "",
    'New': 1,
    'Rejected': 3,
    'Approved': 4,
    'RecordsImported': 7,
    'PendingPayment': 8,
    'BodyPartsAdded': 9,
    'X-RayBodyPending': 10,
    'X-RayBodyFilesImported': 12,
    'ReadyforDownload': 13,
    'Completed': 14,
    'X-RayWaitingForApproval': 15,
    'X-RayApproved': 16,
    'AdvanceFeePending':17,
}    

export const HideBillingDetailsInStatus = [
    'New', 'Approved','AdvanceFeePending', "Rejected"
]
