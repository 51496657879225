export const addRequests = (state, action) => {
    let tempList = action.payload;
    
    let requestList = [];
    requestList = tempList?.map((element) => {
        let { UserDocs } = element;
        let parsedDocs = UserDocs?.split(",");
        let docs = []; let frontDocId, backDocId, authDocId, Files;
        parsedDocs?.forEach((el) => {
            let docId = parseInt(el.split(":")[0]);
            let docTypeId = parseInt(el.split(":")[1]);
            docs.push({id: docId, documentTypeId: docTypeId})
        });
        docs?.filter((current) => [1, 2, 20, 21].includes(current.documentTypeId)
        ).map((current, index) => { index === 0 ? frontDocId = (current?.id) : backDocId = (current?.id)});

        docs?.filter((current) => [30,41,42,43].includes(current.documentTypeId)
        ).map((current) => authDocId = (current?.id));

        docs?.filter((current) => [4].includes(current.documentTypeId)
        ).map((current) => Files = (current?.id))

        return {...element, DocsInfo: { frontDocId, backDocId, authDocId, Files }};
    })
    
    state.requestList = (requestList)? [...requestList].sort((a,b)=> b?.DocumentRequestOrderId - a?.DocumentRequestOrderId) : []

    const { pageSize, pageIndex } = state.tableMetadata;
    state.tableMetadata.total = requestList?.length;
    const tableDataStartIndex = (pageIndex - 1) * pageSize;
    const tableDateEndIndex = (tableDataStartIndex + pageSize) - 1;
    const tableData = requestList?.slice(tableDataStartIndex, tableDateEndIndex + 1);

    state.tableData = tableData;
    state.errorMessage = null;
    state.loading = false;
}

export const filterRequests = (state, action) => {
    const requestList = state.requestList;
    // TODO (patrick): add filtering here
    const { pageSize, pageIndex } = state.tableMetadata;

    const tableDataStartIndex = (pageIndex - 1) * pageSize;
    const tableDateEndIndex = (tableDataStartIndex + pageSize) - 1;
    const tableData = requestList.slice(tableDataStartIndex, tableDateEndIndex + 1);
    state.tableData = tableData;
}

export const failRequests = (state, action) => {
    const errorMessage = action.payload;
    state.requestList = [];
    state.tableData = [];
    state.errorMessage = errorMessage;
    state.loading = false;
}

export const pendingRequests = (state, action) => {
    state.requestList = [];
    state.tableData = [];
    state.errorMessage = [];
    state.loading = true;
}

export const requestStatusCountPending = (state, action) => {
    state.requestCounts = [];
    state.loading = true;
    state.errorMessage = [];
}

export const requestStatusCountComplete = (state, action) => {
    state.requestStatusCount = action.payload;
    state.loading = false;
    state.errorMessage = [];
}

export const requestStatusCountFailed = (state, action) => {
    state.requestCounts = [];
    state.loading = false;
    state.errorMessage = action.payload;
}

export const getAdminEntityCountsPending = (state, action) => {
    state.adminEntityCounts = {};
    state.loading = true;
    state.errorMessage = [];
}

export const getAdminEntityCountsComplete = (state, action) => {
    state.adminEntityCounts = action.payload;
    state.loading = false;
    state.errorMessage = [];
}

export const getAdminEntityCountsFailed = (state, action) => {
    state.adminEntityCounts = {};
    state.loading = false;
    state.errorMessage = action.payload;
}
