import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminEditRequestConstants } from "../../constants";
import AdminRequestService from "../../../../../services/admin/adminRequestService";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";

//get user uploaded files data
export const getDocumentRequestUserFiles = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestUserFiles`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug(
        "Get Document Request User Files Thunk Payload",
        payload
      );
      return await AdminRequestService.getDocumentRequestUserFiles(payload);
    } catch (error) {
      console.error(
        "Failed to get admin document request user files",
        error
      );
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

//get admin uploaded files data
export const getDocumentRequestAdminFiles = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestAdminFiles`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug(
        "Get Document Request Admin Files Thunk Payload",
        payload
      );
      return await AdminRequestService.getDocumentRequestAdminFiles(payload);
    } catch (error) {
      console.error(
        "Failed to get admin document request admin files",
        error
      );
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const certifyNoRecords = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/certifyNoRecords`,
  async (payload, { rejectWithValue }) => {
    const { id, ...bodyData } = payload;
    try {
      console.debug("Success certifyNoRecords", payload);
      return await AdminRequestService.certifyNoRecords(id,
        bodyData
      );
    } catch (error) {
      console.error("Failed certifyNoRecords", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const generateDeclarationOfRecord = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/generateDeclarationOfRecord`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Success generate DeclarationOfRecord", payload);
      return await AdminRequestService.generateDeclarationOfRecord(payload);
    } catch (error) {
      console.error("Failed generate DeclarationOfRecord", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);


export const uploadZipFile = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/uploadZipFile`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Success generate DeclarationOfRecord", payload);
      return await AdminRequestService.uploadZipFile(payload);
    } catch (error) {
      console.error("Failed generate DeclarationOfRecord", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);


// //update xray breakdown
// export const updateXrayBreakdown = createAsyncThunk(
//   `${AdminEditRequestConstants.StoreSliceName}/updateXrayBreakdown`,
//   async (payload, { rejectWithValue, getState }) => {
//     try {
//       const state = getState();
//       const documentRequestXrayBreakdownApiPayload =
//         state.adminEditRequest.apiPayload.documentRequestXrayBreakdown;
//       return await AdminRequestService.updateDocumentRequestXrayBreakdown(
//         payload,
//         documentRequestXrayBreakdownApiPayload
//       );
//     } catch (error) {
//       console.error("Failed to get update Xray Breakdown", error);
//       const errorDisplayMessage = buildErrorDisplayMessage(error);
//       throw rejectWithValue(errorDisplayMessage);
//     }
//   }
// );