import { createAsyncThunk } from "@reduxjs/toolkit";
import {AdminUserListConstants} from '../../constants'
import { buildErrorDisplayMessage } from "utils/errorMessageBuilder";
import NeweraRequestService from "services/newera/neweraRequestService";


export const getAllUsersList = createAsyncThunk(`${AdminUserListConstants.StoreSliceName}/getAllUsersList`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Details Thunk Payload', payload);
        return await NeweraRequestService.getAllUsersList()
    } catch (error) {
        console.error('Failed to get admin document request', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});
