export const initialState = {
    requestList: [
        {
            "DocumentRequestId": 1,
            "RequestDate": "2022-01-01T00:00:00.000Z",
            "RequestedByUser": "John Doe",
            "DoctorFacilityName": "ABC Hospital",
            "RecordTypeName": "Medical",
            "RequestTypeName": "Single Release",
            "DeliveryDueDate": "2022-01-15T00:00:00.000Z",
            "RequestStatusName": "Pending",
            "RequestedBy": "Patient",
            "InternalRefNum": "",
            "BilledAmount": 100,
            "PaidAmount": 10.2,
            "DueAmount": 89.8
        },
        {
            "DocumentRequestId": 2,
            "RequestDate": "2022-01-02T00:00:00.000Z",
            "RequestedByUser": "Jane Doe",
            "DoctorFacilityName": "XYZ Clinic",
            "RecordTypeName": "X-Ray",
            "RequestTypeName": "Workers Compensation",
            "DeliveryDueDate": "2022-01-16T00:00:00.000Z",
            "RequestStatusName": "Approved",
            "RequestedBy": "Attorney Office",
            "InternalRefNum": "1091201R",
            "BilledAmount": 100,
            "PaidAmount": 10.2,
            "DueAmount": 89.8
        },
        {
            "DocumentRequestId": 3,
            "RequestDate": "2022-01-03T00:00:00.000Z",
            "RequestedByUser": "Bob Smith",
            "DoctorFacilityName": "123 Hospital",
            "RecordTypeName": "Medical",
            "RequestTypeName": "Civil Subpoena",
            "DeliveryDueDate": "2022-01-17T00:00:00.000Z",
            "RequestStatusName": "Rejected",
            "RequestedBy": "Patient",
            "InternalRefNum": "",
            "BilledAmount": 1000,
            "PaidAmount": 100.2,
            "DueAmount": 890.8
        },
        {
            "DocumentRequestId": 4,
            "RequestDate": "2022-01-04T00:00:00.000Z",
            "RequestedByUser": "Alice Johnson",
            "DoctorFacilityName": "ABC Hospital",
            "RecordTypeName": "Medical",
            "RequestTypeName": "Single Release",
            "DeliveryDueDate": "2022-01-18T00:00:00.000Z",
            "RequestStatusName": "Pending",
            "RequestedBy": "Patient",
            "InternalRefNum": "",
            "BilledAmount": 100,
            "PaidAmount": 10.2,
            "DueAmount": 89.8
        },
        {
            "DocumentRequestId": 5,
            "RequestDate": "2022-01-05T00:00:00.000Z",
            "RequestedByUser": "David Lee",
            "DoctorFacilityName": "XYZ Clinic",
            "RecordTypeName": "X-Ray",
            "RequestTypeName": "Workers Compensation",
            "DeliveryDueDate": "2022-01-19T00:00:00.000Z",
            "RequestStatusName": "Approved",
            "RequestedBy": "Attorney",
            "InternalRefNum": "9018RABC"
        },
        {
            "DocumentRequestId": 6,
            "RequestDate": "2022-01-06T00:00:00.000Z",
            "RequestedByUser": "Karen Chan",
            "DoctorFacilityName": "123 Hospital",
            "RecordTypeName": "Medical",
            "RequestTypeName": "Civil Subpoena",
            "DeliveryDueDate": "2022-01-20T00:00:00.000Z",
            "RequestStatusName": "Rejected",
            "RequestedBy": "Patient",
            "InternalRefNum": "",
            "BilledAmount": 100,
            "PaidAmount": 10.2,
            "DueAmount": 89.8
        },
        {
            "DocumentRequestId": 7,
            "RequestDate": "2022-01-07T00:00:00.000Z",
            "RequestedByUser": "Adam Black",
            "DoctorFacilityName": "ABC Hospital",
            "RecordTypeName": "Medical",
            "RequestTypeName": "Single Release",
            "DeliveryDueDate": "2022-01-21T00:00:00.000Z",
            "RequestStatusName": "Pending",
            "RequestedBy": "Patient",
            "InternalRefNum": "",
            "BilledAmount": 100,
            "PaidAmount": 10.2,
            "DueAmount": 89.8
        },
        {
            "DocumentRequestId": 8,
            "RequestDate": "2022-01-08T00:00:00.000Z",
            "RequestedByUser": "Mary White",
            "DoctorFacilityName": "XYZ Clinic",
            "RecordTypeName": "X-Ray",
            "RequestTypeName": "Workers Compensation",
            "DeliveryDueDate": "2022-01-22T00:00:00.000Z",
            "RequestStatusName": "Approved",
            "RequestedBy": "Patient",
            "InternalRefNum": "",
            "BilledAmount": 100,
            "PaidAmount": 10.2,
            "DueAmount": 89.8
        }
    ],
    tableData: [],
    loading: false,
    tableMetadata: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        query: '',
        sort: {
            orderDate: '',
            key: ''
        }
    },
    filter: {
        name: '',
        category: ['status'],
        status: [0, 1, 2],
        requestStatus: 0
    },
    requestStatusCount: [],
    adminEntityCounts: {}
};