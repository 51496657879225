import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { OrderListConstants } from "../constants";
import { getOrderList } from "./thunks/getOrderListThunk";
import { getOrderListComplete, getOrderListFailure, getOrderListPending } from "./reducers/getOrderListReducer";
import { getOrderSummary } from "./thunks/getOrderSummaryThunk";
import { getOrderSummaryComplete, getOrderSummaryFailure, getOrderSummaryPending } from "./reducers/getOrderSummaryReducer";

const slice = createSlice({
  name: OrderListConstants.StoreSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
   .addCase(getOrderList.pending, getOrderListPending)
   .addCase(getOrderList.fulfilled, getOrderListComplete)
   .addCase(getOrderList.rejected, getOrderListFailure)

   .addCase(getOrderSummary.pending, getOrderSummaryPending)
   .addCase(getOrderSummary.fulfilled, getOrderSummaryComplete)
   .addCase(getOrderSummary.rejected, getOrderSummaryFailure)
  },
});

export {getOrderList};
export const {} = slice.actions;
export default slice.reducer;
