export const completeFormSubmission = (state, action) => {
    state.loading.formData = false
    state.error.formData = null
    state.success.formData = action.payload;
};

export const pendingFormSubmission = (state) => {
    state.loading.formData = true
    state.error.formData = null
    state.success.formData = false
};

export const failFormSubmission = (state, action) => {
    state.loading.formData = false
    state.error.formData = action.payload
    state.success.formData = false
};