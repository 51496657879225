import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildErrorDisplayMessage } from "utils/errorMessageBuilder";
import { OrderListConstants } from "../../constants";
import PatientRequestService from "services/patient/patientRequestService";

export const getOrderSummary = createAsyncThunk(
  `${OrderListConstants.StoreSliceName}/getOrderSummary`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await PatientRequestService.getOrderSummary();
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
