export const updateAttorneyOfficeDetailsPending = (state, action) => {
    console.debug('Update Attorney Office Reducer Pending', action);
    state.error.updateAttorneyOfficeDetails = null;
    state.loading.updateAttorneyOfficeDetails = true;
    state.serverResponse = null
};

export const updateAttorneyOfficeDetailsComplete = (state, action) => {
    console.debug('Update Attorney Office Reducer Complete', action);
    state.error.updateAttorneyOfficeDetails = null;
    state.loading.updateAttorneyOfficeDetails = false;
    state.serverResponse = action.payload

};

export const updateAttorneyOfficeDetailsFailure = (state, action) => {
    console.debug('Update Attorney Office Reducer Failure', action);
    state.error.updateAttorneyOfficeDetails = action.payload;
    state.loading.updateAttorneyOfficeDetails = false;
    state.serverResponse = action.payload
};
