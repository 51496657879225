export const initialState = {
  formData: {
    firstName: "",
    middleName: "",
    lastName: "",
    patientDob: "",
    patientSsn: "",
    selectType: "1",
    paymentMode: "1",
    doctorFacility: "",
    requestType: "",
    doctorFacilityPhone: "",
    others: "",
    doctorFacilityFax: "",
    deliveryBy: "",
    doctorFacilityAddress: "",
    recordType: "",
    medicalRecordNumber: "",
    govtIdDocumentType: "",
    requestDate_1: "0",
    requestDate_2: "0",
    requestDate_3: "0",
    termsAndConditions:false,
    hippaDeclarationScan:'',
    passportCover: "",
    passportDataPage: "",
    nationalIdFront: "",
    nationalIdBack: "",
    driversLicenseFront: "",
    driversLicenseBack: "",
    orgId:"",
    requestOnBehalf:''
  },
  loading: {
    formData:false
  },
  error: {
    formData: null
  },
  success: {
    formData :false
  },
  // isSubmitting: false,
  // isSubmissionSuccess: true,
  // submissionError: "",
  metadata: {
    doctorFacilities: [],
    requestTypes: [],
    recordTypes: [],
    govtIdDocumentTypes: [],
    isMetadataLoading: false,
    isMetadataAvailable: false,
    error: null,
  },
};
