import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildErrorDisplayMessage } from "utils/errorMessageBuilder";
import { OrderListConstants } from "../../constants";
import PatientRequestService from "services/patient/patientRequestService";

export const getOrderList = createAsyncThunk(
  `${OrderListConstants.StoreSliceName}/getOrderList`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await PatientRequestService.getOrderList(payload.scope, payload.filter);
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
