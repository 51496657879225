import { initialState } from "./state";
import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanyDetails,
  getCompanyList,
  updateCompanyUserDetails,
} from "./thunks/getCompanyListThunk";
import {
  getCompanyDetailsComplete,
  getCompanyDetailsFailure,
  getCompanyDetailsPending,
  getCompanyListComplete,
  getCompanyListFailure,
  getCompanyListPending,
} from "./reducers/getCompanyListReducer";
import { CompanyListConstant } from "../constants";
import { addCompany } from "./thunks/addCompanyThunk";
import {
  addCompanyComplete,
  addCompanyFailure,
  addCompanyPending,
  updateCompanyUserDetailsComplete,
  updateCompanyUserDetailsFailure,
  updateCompanyUserDetailsPending,
} from "./reducers/addCompanyReducer";

const slice = createSlice({
  name: CompanyListConstant.StoreSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyList.pending, getCompanyListPending)
      .addCase(getCompanyList.fulfilled, getCompanyListComplete)
      .addCase(getCompanyList.rejected, getCompanyListFailure)

      //single company details
      .addCase(getCompanyDetails.pending, getCompanyDetailsPending)
      .addCase(getCompanyDetails.fulfilled, getCompanyDetailsComplete)
      .addCase(getCompanyDetails.rejected, getCompanyDetailsFailure)

      //add company
      .addCase(addCompany.pending, addCompanyPending)
      .addCase(addCompany.fulfilled, addCompanyComplete)
      .addCase(addCompany.rejected, addCompanyFailure)

      //update user
      .addCase(updateCompanyUserDetails.pending, updateCompanyUserDetailsPending)
      .addCase(updateCompanyUserDetails.fulfilled, updateCompanyUserDetailsComplete)
      .addCase(updateCompanyUserDetails.rejected, updateCompanyUserDetailsFailure)
  },
});

export { getCompanyList, getCompanyDetails, addCompany, updateCompanyUserDetails };
export const {} = slice.actions;
export default slice.reducer;
