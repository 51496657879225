export const getDocumentRequestDetailsPending = (state, action) => {
    console.debug('Document Request Details Reducer Pending', action);
    state.errors.requestDetails = null;
    state.documentRequest = {};
    state.loading.requestDetails = true;
};

export const getDocumentRequestDetailsComplete = (state, action) => {
    console.debug('Document Request Details Reducer Complete', action);
    state.errors.requestDetails = null;
    state.documentRequest = action.payload;
    state.loading.requestDetails = false;
};

export const getDocumentRequestFailure = (state, action) => {
    console.debug('Document Request Details Reducer Failure', action);
    state.errors.requestDetails = action.payload;
    state.loading.requestDetails = false;
};

// doctor facility

export const getDocumentRequestDoctorFacilityPending = (state, action) => {
    console.debug('Document Request doctor Facility Details Reducer Pending', action);
    state.errors.doctorFacility = null;
    state.doctorFacility = {};
    state.loading.doctorFacility = true;
};

export const getDocumentRequestDoctorFacilityComplete = (state, action) => {
    console.debug('Document Request doctor Facility Details Reducer Complete', action);
    state.errors.doctorFacility = null;
    state.doctorFacility = action.payload;
    state.loading.doctorFacility = false;
};

export const getDocumentRequestDoctorFacilityFailure = (state, action) => {
    console.debug('Document Request doctor Facility Details Reducer Failure', action);
    state.errors.doctorFacility = action.payload;
    state.loading.doctorFacility = false;
};


// basic user list

export const getDocumentRequestBasicUserListPending = (state, action) => {
    console.debug('Document Request basic user list Reducer Pending', action);
    state.errors.basicUserList = null;
    state.basicUserList = [];
    state.loading.basicUserList = true;
};

export const getDocumentRequestBasicUserListComplete = (state, action) => {
    console.debug('Document Request basic user list Reducer Complete', action);
    state.errors.basicUserList = null;
    state.basicUserList = action.payload;
    state.loading.basicUserList = false;
};

export const getDocumentRequestBasicUserListFailure = (state, action) => {
    console.debug('Document Request basic user list Reducer Failure', action);
    state.errors.basicUserList = action.payload;
    state.loading.basicUserList = false;
};

// Requesting Party Details

export const getDocumentRequestRequestingPartyDetailsPending = (state, action) => {
    console.debug('Document Request requesting party details Reducer Pending', action);
    state.errors.requestingPartyDetails = null;
    state.requestingPartyDetails = {};
    state.loading.requestingPartyDetails = true;
};

export const getDocumentRequestRequestingPartyDetailsComplete = (state, action) => {
    console.debug('Document Request requesting party details Reducer Complete', action);
    state.errors.requestingPartyDetails = null;
    state.requestingPartyDetails = action.payload;
    state.loading.requestingPartyDetails = false;
};

export const getDocumentRequestRequestingPartyDetailsFailure = (state, action) => {
    console.debug('Document Request requesting party details Reducer Failure', action);
    state.errors.requestingPartyDetails = action.payload;
    state.loading.requestingPartyDetails = false;
};

// request types masterdata

export const getDocumentRequestRequestTypesPending = (state, action) => {
    console.debug('Document Request request types Reducer Pending', action);
    state.errors.requestTypes = null;
    state.metadata.requestTypes = [];
    state.loading.requestTypes = true;
};

export const getDocumentRequestRequestTypesComplete = (state, action) => {
    console.debug('Document Request request types Reducer Complete', action);
    state.errors.requestTypes = null;
    state.metadata.requestTypes = action.payload;
    state.loading.requestTypes = false;
};

export const getDocumentRequestRequestTypesFailure = (state, action) => {

    console.debug('Document Request request types Reducer Failure', action);
    state.errors.requestTypes = action.payload;
    state.loading.requestTypes = false;
};

// record types masterdata

export const getDocumentRequestRecordTypesPending = (state, action) => {
    console.debug('Document Request record types Reducer Pending', action);
    state.errors.recordTypes = null;
    state.metadata.recordTypes = [];
    state.loading.recordTypes = true;
};

export const getDocumentRequestRecordTypesComplete = (state, action) => {
    console.debug('Document Request record types Reducer Complete', action);
    state.errors.recordTypes = null;
    state.metadata.recordTypes = action.payload;
    state.loading.recordTypes = false;
};

export const getDocumentRequestRecordTypesFailure = (state, action) => {
    console.debug('Document Request record types Reducer Failure', action);
    state.errors.recordTypes = action.payload;
    state.loading.recordTypes = false;
};


// DoctorFacility masterdata

export const getDoctorFacilitiesPending = (state, action) => {
    console.debug('getDoctorFacilities Reducer Pending', action);
    state.errors.doctorFacilities = null;
    state.metadata.doctorFacilities = [];
    state.loading.doctorFacilities = true;
};

export const getDoctorFacilitiesComplete = (state, action) => {
    console.debug('getDoctorFacilities Reducer Complete', action);
    state.errors.doctorFacilities = null;
    state.metadata.doctorFacilities = action.payload;
    state.loading.doctorFacilities = false;
};

export const getDoctorFacilitiesFailure = (state, action) => {
    console.debug('getDoctorFacilities Reducer Failure', action);
    state.errors.doctorFacilities = action.payload;
    state.loading.doctorFacilities = false;
};

// internal status domain masterdata

export const getInternalStatusDomainPending = (state, action) => {
    console.debug('Internal status domain Reducer Pending', action);
    state.errors.internalStatusDomain = null;
    state.metadata.internalStatusDomain = [];
    state.loading.internalStatusDomain = true;
};

export const getInternalStatusDomainComplete = (state, action) => {
    console.debug('Internal status domain Reducer Complete', action);
    state.errors.internalStatusDomain = null;
    state.metadata.internalStatusDomain = action.payload;
    state.loading.internalStatusDomain = false;
};

export const getInternalStatusDomainFailure = (state, action) => {
    console.debug('Internal status domain Reducer Failure', action);
    state.errors.internalStatusDomain = action.payload;
    state.loading.internalStatusDomain = false;
};

// status domain masterdata

export const getStatusDomainPending = (state, action) => {
    console.debug(' status domain Reducer Pending', action);
    state.errors.statusDomain = null;
    state.metadata.statusDomain = [];
    state.loading.statusDomain = true;
};

export const getStatusDomainComplete = (state, action) => {
    console.debug(' status domain Reducer Complete', action);
    state.errors.statusDomain = null;
    state.metadata.statusDomain = action.payload;
    state.loading.statusDomain = false;
};

export const getStatusDomainFailure = (state, action) => {
    console.debug(' status domain Reducer Failure', action);
    state.errors.statusDomain = action.payload;
    state.loading.statusDomain = false;
};

// order details update 
export const updateOrderDetailsPending = (state, action) => {
    console.debug("Update Order Details Reducer Pending", action);
    state.errors.updates.orderDetails = null;
    state.loading.updates.orderDetails = true;
  };
  
  export const updateOrderDetailsFailure = (state, action) => {
    console.debug("Update Order Details Reducer Failure", action);
    state.errors.updates.orderDetails = action.payload;
    state.loading.updates.orderDetails = false;
    state.serverResponse.updateOrderDetails = action.payload
  };
  
  export const updateOrderDetailsComplete = (state, action) => {
    console.debug("Update Order Details Reducer Complete", action);
    state.apiPayload.documentRequestOrderDetails = [];
    state.errors.updates.orderDetails = null;
    state.loading.updates.orderDetails = false;
    state.modifications.orderDetails = false;
    state.serverResponse.updateOrderDetails = action.payload
  };

// insurance Company
export const getDocumentRequestInsuranceCompanyPending = (state, action) => {
    console.debug('Document Request doctor Facility Details Reducer Pending', action);
    state.errors.insuranceCompany = null;
    state.insuranceCompany = {};
    state.loading.insuranceCompany = true;
};

export const getDocumentRequestInsuranceCompanyComplete = (state, action) => {
    console.debug('Document Request doctor Facility Details Reducer Complete', action);
    state.errors.insuranceCompany = null;
    state.insuranceCompany = action.payload;
    state.loading.insuranceCompany = false;
};

export const getDocumentRequestInsuranceCompanyFailure = (state, action) => {
    console.debug('Document Request doctor Facility Details Reducer Failure', action);
    state.errors.insuranceCompany = action.payload;
    state.loading.insuranceCompany = false;
};
export const getDocumentRequestAttorneyOfficesComplete = (state, action) => {
    console.debug('getDocumentRequestAttorneyOffices Reducer Complete', action);
    state.errors.attorneyOffice = null;
    state.attorneyOffice = action.payload;
    state.loading.attorneyOffice = false;
}

export const getDocumentRequestAttorneyOfficesFailure = (state, action) => {
    console.debug('getDocumentRequestAttorneyOffices Reducer Failure', action);
    state.errors.attorneyOffice = action.payload;
    state.loading.attorneyOffice = false;
}

export const getDocumentRequestAttorneyOfficesPending = (state, action) => {
    console.debug('getDocumentRequestAttorneyOffices Reducer Pending', action);
    state.errors.attorneyOffice = null;
    state.attorneyOffice = {};
    state.loading.attorneyOffice = true;
}
export const getInsuranceCompaniesPending = (state, action) => {
    console.debug('getInsuranceCompanies Reducer Pending', action);
    state.errors.insuranceCompanies = null;
    state.metadata.insuranceCompanies = [];
    state.loading.insuranceCompanies = true;
};

export const getInsuranceCompaniesComplete = (state, action) => {
    console.debug('getInsuranceCompanies Reducer Complete', action);
    state.errors.insuranceCompanies = null;
    state.metadata.insuranceCompanies = action.payload;
    state.loading.insuranceCompanies = false;
};

export const getInsuranceCompaniesFailure = (state, action) => {
    console.debug('getInsuranceCompanies Reducer Failure', action);
    state.errors.insuranceCompanies = action.payload;
    state.loading.insuranceCompanies = false;
};


export const getAttorneyOfficesPending = (state, action) => {
    console.debug('getAttorneyOfficesPending Reducer Pending', action);
    state.errors.attorneyOffices = null;
    state.metadata.attorneyOffices = [];
    state.loading.attorneyOffices = true;
};

export const getAttorneyOfficesComplete = (state, action) => {
    console.debug('getAttorneyOfficesComplete Reducer Complete', action);
    state.errors.attorneyOffices = null;
    state.metadata.attorneyOffices = action.payload;
    state.loading.attorneyOffices = false;
};

export const getAttorneyOfficesFailure = (state, action) => {
    console.debug('getAttorneyOfficesFailure Reducer Failure', action);
    state.errors.attorneyOffices = action.payload;
    state.loading.attorneyOffices = false;
};