import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileInformationConstant } from "../../constants";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";
import PatientRequestService from "services/patient/patientRequestService";
import { setServerResponse } from "..";
import { updateUserData } from "store/auth/userSlice";

export const updateCompanyDetails = createAsyncThunk(
  `${ProfileInformationConstant.StoreSliceName}/updateCompanyDetails`,
  async (payload, { rejectWithValue, dispatch }) => {
    const { id } = payload;
    try {
      console.debug("update company profile", payload);
      const response = await PatientRequestService.updateCompanyDetails(id, payload);
      dispatch(setServerResponse(response));
      if (response.status === 200) {
        dispatch(
          updateUserData({
            firstName: payload.companyUser.firstName,
            lastName: payload.companyUser.lastName,
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(setServerResponse(error));
      console.error("Failed to update company profile", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
