export const initialState = {
  formData: {
    userType: "",
    personalInfo: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      last4SSN: "",
      phoneNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      acceptTerms: false,
      companyName: "",
      faxNumber: "",
      ein: "",
      contactPersonFirstName: "",
      contactPersonMiddleName: "",
      contactPersonLastName: "",
    },
    addressInfo: {
      addressLine1: "",
      addressLine2: "",
      state: "",
      zipcode: "",
      city: "",
    },
    doctorFacility: {
      facilityName: "",
      ein: "",
      firstName: "",
      lastName: "",
      website: "",
      phone: "",
      email: "",
      recordType: "",
      mode:"0",
      fees:10,
      facilityLogo:"",
      advanceFee:0,
      Instructions:""
    },
    BackOffice: {
      backOfficeName: "",
      ein: "",
      firstName: "",
      lastName: "",
      website: "",
      phone: "",
      email: "",
      recordType: "",
      mode:"0",
      fees:10,
      OfficeLogo:""
    },
    InsuranceCompany: {
      companyName: "",
      ein: "",
      firstName: "",
      lastName: "",
      website: "",
      phone: "",
      email: "",
      recordType: "",
      mode:"0",
      fees:10,
      companyLogo:"",
      advanceFee:0,
      Instructions:"",
    },
    attorney: {
      attorneyName: "",
      ein: "",
      firstName: "",
      lastName: "",
      website: "",
      phone: "",
      email: "",
      recordType: "",
      mode:"0",
      fees:10,
      advanceFee:0,
      Instructions:"",
      attorneyLogo:""
    },
  },
};
