export const getDocumentRequestAdminFilesPending = (state, action) => {
    console.debug("Document Request Admin Files Reducer Pending", action);
    state.errors.adminFiles = null;
    if (state.documentRequest != null) {
      state.documentRequest.adminFiles = [];
    }
    state.loading.adminFiles = true;
  };
  
  export const getDocumentRequestAdminFilesComplete = (state, action) => {
    console.debug("Document Request Admin Files Reducer Complete", action);
    state.errors.adminFiles = null;
    if (state.documentRequest == null) {
      state.documentRequest = {};
    }
    state.documentRequest.adminFiles = action.payload;
    state.loading.adminFiles = false;
  };
  
  export const getDocumentRequestAdminFilesFailure = (state, action) => {
    console.debug("Document Request Admin Files Reducer Failure", action);
    state.errors.adminFiles = action.payload;
    state.loading.adminFiles = false;
  };