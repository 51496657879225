import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminEditRequestConstants } from '../../constants';
import AdminRequestService from '../../../../../services/admin/adminRequestService';
import { buildErrorDisplayMessage } from '../../../../../utils/errorMessageBuilder';
import NeweraRequestService from 'services/newera/neweraRequestService';
import {getRequestsCountsByStatus} from '../../../requestList/store'

export const getDocumentRequestDetails = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getRequest`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Details Thunk Payload', payload);
        return await AdminRequestService.getDocumentRequest(payload);
    } catch (error) {
        console.error('Failed to get admin document request', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

export const getDocumentRequestDoctorFacility = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getRequestDoctorFacility`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Doctor Facility Details Thunk Payload', payload);
        return await NeweraRequestService.getDoctorFacilityDetails(payload);
    } catch (error) {
        console.error('Failed to get admin document request doctor facility', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

export const getDocumentRequestBasicUserList = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getRequestBasicUserList`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Basic User List Thunk Payload', payload);
        return await NeweraRequestService.getBasicUserList();
    } catch (error) {
        console.error('Failed to get admin document request basic user list', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//requesting party details
export const getDocumentRequestRequestingPartyDetails = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getRequestRequestingParty`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Requesting Party Details Thunk Payload', payload);
        return await NeweraRequestService.getRequestingPartyDetails(payload);
    } catch (error) {
        console.error('Failed to get admin document request requesting party', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//record type master data
export const getDocumentRequestRecordTypes = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getRequestRecordTypes`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request record types Thunk Payload', payload);
        return await NeweraRequestService.getRecordTypes();
    } catch (error) {
        console.error('Failed to get admin document record types', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//getDoctorFacilities data
export const getDoctorFacilities = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getDoctorFacilities`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get DoctorFacilityList Thunk Payload', payload);
        return await NeweraRequestService.getDoctorFacilities();
    } catch (error) {
        console.error('Failed getDoctorFacilityList', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});


//request type master data
export const getDocumentRequestRequestTypes = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getRequestRequestTypes`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Request types Thunk Payload', payload);
        return await NeweraRequestService.getRequestTypes();
    } catch (error) {
        console.error('Failed to get admin document request types', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//internal status type master data
export const getInternalStatusDomain = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getInternalStatusDomain`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Internal status domain Thunk Payload', payload);
        return await NeweraRequestService.getInternalStatusDomain();
    } catch (error) {
        console.error('Failed to get internal status domain', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//status type master data
export const getStatusDomain = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getStatusDomain`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get status domain Thunk Payload', payload);
        return await NeweraRequestService.getStatusDomain();
    } catch (error) {
        console.error('Failed to get status domain', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//update order details thunk
export const updateOrderDetails = createAsyncThunk(
    `${AdminEditRequestConstants.StoreSliceName}/updateOrderDetails`,
    async (payload, { rejectWithValue, getState, dispatch }) => {
      try {
        const state = getState();
        const documentRequestOrderDetailsApiPayload =
          state.adminEditRequest.apiPayload.documentRequestOrderDetails;
        var response = await AdminRequestService.updateDocumentRequestOrderDetails(
          payload[0],
          payload[1]
        //   documentRequestOrderDetailsApiPayload
        );
        dispatch(getDocumentRequestDetails(payload[0]));
        dispatch(getRequestsCountsByStatus());
        return response;
      } catch (error) {
        console.error("Failed to get update Order Details", error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
      }
    }
  );

  export const getDocumentRequestInsuranceCompany = createAsyncThunk(
    `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestInsuranceCompany`,
     async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Insurance Company Details Thunk Payload', payload);
        return await NeweraRequestService.getInsuranceCompanyDetails(payload);
    } catch (error) {
        console.error('Failed to get admin document request Insurance Company', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//getInsuranceCompanies data
export const getInsuranceCompanies = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getInsuranceCompanies`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get getInsuranceCompanies Thunk Payload', payload);
        return await NeweraRequestService.getAllInsuranceCompanyList();
    } catch (error) {
        console.error('Failed getInsuranceCompanies', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

//getAttorneyOffices data
export const getDocumentRequestAttorneyOffices = createAsyncThunk(
    `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestAttorneyOffices`,
     async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Attorney Details Thunk Payload', payload);
        return await NeweraRequestService.getAttorneyOfficeDetails(payload);
    } catch (error) {
        console.error('Failed to get admin document request Law Offices', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});

export const getAttorneyOffices = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getAttorneyOffices`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get getAttorneyOffices Thunk Payload', payload);
        return await NeweraRequestService.getAllAttorneyList();
    } catch (error) {
        console.error('Failed getInsuranceCompanies', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});