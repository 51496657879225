import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminEditRequestConstants } from "../../constants";
import AdminRequestService from "../../../../../services/admin/adminRequestService";
import RequestStatusTypeService from "../../../../../services/metadata/requestStatusTypesService";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";
import { GetFeeTypesService } from "services/metadata";
import { logDOM } from "@testing-library/react";
import { logDebug } from "services/telemetry/logger";
import { DocumentRequestFormConstants } from "views/documentsRequest/constants";
import { getDocumentRequestDetails, getInternalStatusDomain, getStatusDomain } from "./documentRequestDetailsThunk";
import { getRequests, getRequestsCountsByStatus } from "views/admin/requestList/store";
import { InternalStatusEnum } from "views/admin/requestList/constants";
import { Notification, toast } from "components/ui";

export const getDocumentRequestInvoiceDetails = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestInvoiceDetails`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug(
        "Get Document Request Status History Thunk Payload",
        payload
      );
      return await AdminRequestService.getDocumentRequestInvoiceDetails(
        payload
      );
    } catch (error) {
      console.error(
        "Failed to get admin document request status history",
        error
      );
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const getServiceFeesForInvoice = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getServiceFeesForInvoice`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Success get invoice details", payload);
      return await AdminRequestService.getServiceFeesForInvoice(payload);
    } catch (error) {
      console.error("Failed get invoice details", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
)

//request invoice info API thunk
export const getDocumentRequestInvoiceInfo = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestInvoiceInfo`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Get Document Request Invoice Info Thunk Payload", payload);
      return await AdminRequestService.getDocumentRequestInvoiceInfo(payload);
    } catch (error) {
      console.error("Failed to get admin document request invoice info", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const updateDocumentRequestInvoiceDetails = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/updateDocumentRequestInvoiceDetails`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const documentRequestInvoiceSummaryApiPayload = state.adminEditRequest.apiPayload.documentRequestInvoiceDetails
      const documentRequestInvoiceApiPayload =
        state.adminEditRequest.apiPayload.documentRequestUpdatedInvoiceData;

      let data = [];
      

      documentRequestInvoiceApiPayload.map((each) => {
        if (each._action === "DELETE") {
          data.push({
            id: each.id,
            _action: each._action,
          });
        }

        if (each._action === "ADD") {
          data.push({
            serviceFeeId: each.serviceFeeId,
            unitPrice: each.unitPrice,
            quantity: each.quantity,
            subTotal: each.subTotal,
            tax: each.tax,
            updatedBy: each.updatedBy,
            _action: each._action,
            description: each.description
          });
        }
        if (each._action === "UPDATE") {
          data.push({
            id: each.id,
            serviceFeeId: each.serviceFeeId,
            unitPrice: each.unitPrice,
            quantity: each.quantity,
            subTotal: each.subTotal,
            tax: each.tax,
            updatedBy: each.updatedBy,
            _action: each._action,
            description: each.description
          });
        }
      });

      const bodyData = {
        invoice: documentRequestInvoiceSummaryApiPayload,
        patchInvoiceItems: data,
      };
      if(!documentRequestInvoiceSummaryApiPayload.id && documentRequestInvoiceSummaryApiPayload._action !== 'ADD'){
        // no invoice id
       return toast.push(<Notification title="Error" type="danger" >
          Unable to perform the action at the moment. Please try after some time
        </Notification>)
      }
      return await AdminRequestService.updateDocumentRequestInvoiceDetails(
        payload,
        bodyData
      );
    } catch (error) {
      console.error("Failed to get update Status History", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const createDocumentRequestCheckPayment = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/createDocumentRequestCheckPayment`,
  async (payload, { rejectWithValue }) => {
    const { id, ...bodyData } = payload;
    try {
      console.debug("Success Create document request check payment", payload);
      return await AdminRequestService.createDocumentRequestCheckPayment(
        id,
        bodyData
      );
    } catch (error) {
      console.error("Failed Create document request check payment", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const createFeeDueItem = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/createDocumentRequestCheckPayment`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Success Create document request check payment", payload);
      return await AdminRequestService.createFeeDueItem(payload);
    } catch (error) {
      console.error("Failed Create document request check payment", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const createFeePaymentRequest = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/createDocumentRequestCheckPayment`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Success Create document request check payment", payload);
      return await AdminRequestService.createFeePaymentRequest(payload);
    } catch (error) {
      console.error("Failed Create document request check payment", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const createDocumentRequestXrayCheckPayment = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/createDocumentRequestXrayCheckPayment`,
  async (payload, { rejectWithValue }) => {
    const { docRequestId, ...bodyData } = payload;
    try {
      console.debug("Success Create document request xray check payment", payload);
      return await AdminRequestService.createDocumentRequestXrayCheckPayment(
        docRequestId,
        bodyData
      );
    } catch (error) {
      console.error("Failed Create document request xray check payment", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const writeOffPayment = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/writeOffPayment`,
  async (payload, { rejectWithValue }) => {
    const { id, ...bodyData } = payload;
    try {
      console.debug("Success Create document request payment write off", payload);
      return await AdminRequestService.writeOffPayment(
        id,
        bodyData
      );
    } catch (error) {
      console.error("Failed Create document request payment write off", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const getFeeTypes = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getFeeTypes`,
  async (payload, { rejectWithValue }) => {
    try {
      return await GetFeeTypesService.getFeeTypes(payload);
    } catch (error) {
      console.error("Failed to get request status types", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const generateInvoice = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/generateInvoice`,
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      console.debug("Success generate invoice", payload);
      var response = await AdminRequestService.generateInvoice(payload);
      dispatch(getDocumentRequestDetails(payload));
      return response;
    } catch (error) {
      console.error("Failed generate invoice", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const getUniversalServiceFeesForInvoice = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getUniversalServiceFeesForInvoice`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Success get Universal Serive Fees for invoice details");
      return await AdminRequestService.getUniversalServiceFeesForInvoice();
    } catch (error) {
      console.error("Failed get Universal Serive Fees for invoice details", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
)

export const documentRequestGetPaymentHistory = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/documentRequestGetPaymentHistory`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdminRequestService.getPaymentHistory(payload);
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const documentRequestGetPaymentHistoryByDocRequestId = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/documentRequestGetPaymentHistoryByDocRequestId`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdminRequestService.getPaymentHistoryByDocRequestId(payload);
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/deleteInvoice`,
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const {invoiceId, docId, currentPath} = payload;
      console.debug("Success delete invoice", payload);
      var response = await AdminRequestService.deleteInvoice(invoiceId, docId);
      await dispatch(getDocumentRequestInvoiceInfo(docId));
      await dispatch(getDocumentRequestInvoiceDetails(docId));
      await  dispatch(getDocumentRequestDetails(docId));
      dispatch(getInternalStatusDomain());
      dispatch(getStatusDomain());
      dispatch(documentRequestGetPaymentHistoryByDocRequestId(docId));
      dispatch(getRequestsCountsByStatus());
      dispatch(getRequests(InternalStatusEnum[currentPath]));

      return response;
    } catch (error) {
      console.error("Failed generate invoice", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
