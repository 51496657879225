import { initialState } from "./state";
const { createSlice } = require("@reduxjs/toolkit");
const { ForgotPasswordFormConstants } = require("../constants");

const slice = createSlice({
    name: ForgotPasswordFormConstants.StoreSliceName,
    initialState,
    reducers:{
        setPasswordResetFields : (state, action) => {
            state.data = {...state.data, ...action.payload}
        }
    }
})

export const {setPasswordResetFields} = slice.actions;
export default slice.reducer