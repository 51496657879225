import React from "react";
import Container from "./Container";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
  );

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
    return window.location.reload();
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
  }
};

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: {} };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    retryPageLoading();
    this.setState({
      hasError: true,
      errorInfo: error,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="bg-[#0E131B] min-h-screen">
          <div className="relative flex flex-col items-center justify-center">
            <Container>
              <div className=" flex flex-col justify-center items-center py-32 z-20 ">
                <div className="min-w-[480px] text-center">
                  <div className="mb-8">
                    <h3 className="mb-4 font-semibold text-4xl text-white">
                      Something went wrong.
                    </h3>
                    <p className="text-2xl">
                      {JSON.stringify(this.state.errorInfo)}
                    </p>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
