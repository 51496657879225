import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";
import NeweraRequestService from "services/newera/neweraRequestService";
import { CompanyListConstant } from "../../constants";
import { Notification, toast } from "components/ui";

export const getCompanyList = createAsyncThunk(
  `${CompanyListConstant.StoreSliceName}/getCompanyList`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Get Company List", payload);
      return await NeweraRequestService.getCompanyList(payload);
    } catch (error) {
      console.error("Get Company List", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

//single company by id

export const getCompanyDetails = createAsyncThunk(
  `${CompanyListConstant.StoreSliceName}/getCompanyDetails`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Get Company Details Thunk Payload", payload);
      return await NeweraRequestService.getCompanyDetails(payload);
    } catch (error) {
      console.error("Failed to get company details", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
       if (error?.response?.status === 404) {

        toast.push(
          <Notification title="Error" type="danger" closable>
            Unable to find user details
          </Notification>
        );
        setTimeout(() => {
          // invalid user Error redirect him to home page
          window.location.href = window.location.origin;
        }, 700)
      }
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const updateCompanyUserDetails = createAsyncThunk(
  `${CompanyListConstant.StoreSliceName}/updateCompanyUserDetails`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug("Get Company Details Thunk Payload", payload);
      return await NeweraRequestService.updateCompanyUserDetails(payload);
    } catch (error) {
      console.error("Failed to get company details", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
