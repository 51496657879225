import React from 'react'
import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'
import  forEach  from 'lodash/forEach';
const AuthorityCheck = props => {

	const { userAuthority = [], authority = [], children } = props

	const roleMatched = useAuthority(userAuthority, authority)
	if (roleMatched) {
		let data = children?.props?.children;
		for (let index = data?.length - 1; index >= 0; index--) {
			const item = data[index];
			if (item?.props) {
			  // #TODO this logic expects the logged in user to have only one role
			  // we check that role against available roles
			  if (!item.props.authority.includes(item.props.userAuthority[0])) {
				data.splice(index, 1);
			  }
			}
		  }	  
	  }

	return roleMatched ? children : <></>
}

AuthorityCheck.propTypes = {
	userAuthority: PropTypes.array,
	authority: PropTypes.array,
}

export default AuthorityCheck