export const DocumentRequestFormConstants = {
    StoreSliceName: 'documentRequestForm',
    OtherRequestTypeId: 5,
    HipplaDeclarationFormLink: 'https://www.google.com',
    ApiActions: {
        Add: 'ADD',
        Delete: 'DELETE',
        Update: 'UPDATE',
    },
};
