export const updateDoctorFacilityDetailsPending = (state, action) => {
    console.debug('Update Doctor Facility Reducer Pending', action);
    state.error.updateDoctorFacilityDetails = null;
    state.loading.updateDoctorFacilityDetails = true;
    state.serverResponse = null
};

export const updateDoctorFacilityDetailsComplete = (state, action) => {
    console.debug('Update Doctor Facility Reducer Complete', action);
    state.error.updateDoctorFacilityDetails = null;
    state.loading.updateDoctorFacilityDetails = false;
    state.serverResponse = action.payload

};

export const updateDoctorFacilityDetailsFailure = (state, action) => {
    console.debug('Update Doctor Facility Reducer Failure', action);
    state.error.updateDoctorFacilityDetails = action.payload;
    state.loading.updateDoctorFacilityDetails = false;
    state.serverResponse = action.payload
};
