import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminEditRequestConstants } from "../../constants";
import AdminRequestService from "../../../../../services/admin/adminRequestService";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";

//get xray breakdown data
export const getDocumentRequestXrayBreakdown = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/getDocumentRequestXrayBreakdown`,
  async (payload, { rejectWithValue }) => {
    try {
      console.debug(
        "Get Document Request Xray Breakdown Thunk Payload",
        payload
      );
      return await AdminRequestService.getDocumentRequestXrayBreakdown(payload);
    } catch (error) {
      console.error(
        "Failed to get admin document request status history",
        error
      );
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

//update xray breakdown
export const updateXrayBreakdown = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/updateXrayBreakdown`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const documentRequestXrayBreakdownApiPayload =
        state.adminEditRequest.apiPayload.documentRequestXrayBreakdown;
      return await AdminRequestService.updateDocumentRequestXrayBreakdown(
        payload,
        documentRequestXrayBreakdownApiPayload
      );
    } catch (error) {
      console.error("Failed to get update Xray Breakdown", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

//xray body parts master data
export const getXrayBodyParts = createAsyncThunk(`${AdminEditRequestConstants.StoreSliceName}/getXrayBodyParts`, async (payload, { rejectWithValue }) => {
  try {
      console.debug('Get Xray Body Parts Thunk Payload', payload);
      return await AdminRequestService.getXrayBodyParts();
  } catch (error) {
      console.error('Failed to get xray body parts', error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
  }
});