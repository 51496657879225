export const getMessagesPending = (state, action) => {
    console.debug('Get Messages Reducer Pending', action);
    state.errors.support = null;
    state.support = [];
    state.loading.support = true;
};

export const getMessagesComplete = (state, action) => {
    console.debug('Get Messages Reducer Complete', action);
    state.errors.support = null;
    state.support = action.payload;
    state.loading.support = false;
};

export const getMessagesFailure = (state, action) => {
    console.debug('Get Messages Reducer Failure', action);
    state.errors.support = action.payload;
    state.loading.support = false;
};

export const getNotesPending = (state, action) => {
    console.debug('Get Messages Reducer Pending', action);
    state.errors.notes = null;
    state.notes = [];
    state.loading.notes = true;
};

export const getNotesComplete = (state, action) => {
    console.debug('Get Messages Reducer Complete', action);
    state.errors.notes = null;
    state.notes = action.payload;
    state.loading.notes = false;
};

export const getNotesFailure = (state, action) => {
    console.debug('Get Messages Reducer Failure', action);
    state.errors.notes = action.payload;
    state.loading.notes = false;
};


export const sendMessagesPending = (state, action) => {
    console.debug('Send Messages Reducer Pending', action);
    state.errors.sendMessage = null;
    state.loading.sendMessage = true;
};

export const sendMessagesComplete = (state, action) => {
    console.debug('Send Messages Reducer Complete', action);
    state.errors.sendMessage = null;
    state.loading.sendMessage = false;
};

export const sendMessagesFailure = (state, action) => {
    console.debug('Send Messages Reducer Failure', action);
    state.errors.sendMessage = action.payload;
    state.loading.sendMessage = false;
};