import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    userId: '',
    userName: '',
    firstName:'',
    lastName:'',
    authority: []
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        updateUserData: (state, action) => {
            // state = {...state, ...action.payload}
            state.firstName = action.payload.firstName
            state.lastName = action.payload.lastName
        },
        userLoggedOut: () => initialState,
	},
})

export const { setUser,userLoggedOut, updateUserData } = userSlice.actions

export default userSlice.reducer