export const getOrderSummaryPending = (state, action) => {
    state.loading.patient = true;
};

export const getOrderSummaryComplete = (state, action) => {
    console.debug('Get Order Summary Reducer Complete', action);
    const data = action?.payload
    const documentRequestSummary = data?.documentRequestSummary;
    state.orderSummaryData = documentRequestSummary;
};

export const getOrderSummaryFailure = (state, action) => {
    console.debug('Get Order Summary Reducer Failure', action);
    state.errors.patient = action.payload;
    state.loading.patient = false;
};
