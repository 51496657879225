import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminEditRequestConstants } from "../../constants";
import { buildErrorDisplayMessage } from "utils/errorMessageBuilder";
import AdminRequestService from "services/admin/adminRequestService";

export const documentRequestGetMessages = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/documentRequestGetMessages`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdminRequestService.getDocumentRequestMessages(payload);
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const documentRequestGetNotes = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/documentRequestGetNotes`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdminRequestService.getDocumentRequestNotes(payload);
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);

export const documentRequestAddNote = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/documentRequestAddNote`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdminRequestService.addNote(payload);
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);


export const documentRequestSendMessages = createAsyncThunk(
  `${AdminEditRequestConstants.StoreSliceName}/documentRequestSendMessages`,
  async (payload, { rejectWithValue }) => {
    const {id, ...data} = payload
    try {
      const response = await AdminRequestService.sendMessage(id, data);
      return response;
    } catch (error) {
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);