import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileInformationConstant } from "../../constants";
import { buildErrorDisplayMessage } from "../../../../../utils/errorMessageBuilder";
import PatientRequestService from "services/patient/patientRequestService";
import { setServerResponse } from "..";
import { updateUserData } from "store/auth/userSlice";

export const updateUserDetails = createAsyncThunk(
  `${ProfileInformationConstant.StoreSliceName}/updateUserDetails`,
  async (payload, { rejectWithValue, dispatch }) => {
    const { id, ...data } = payload;
    try {
      console.debug("update user profile", payload);
      const response = await PatientRequestService.updateUserDetails(id, data);
      dispatch(setServerResponse(response));
      if (response.status === 200) {
        dispatch(
          updateUserData({
            firstName: data.firstName,
            lastName: data.lastName,
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(setServerResponse(error));
      console.error("Failed to update user profile", error);
      const errorDisplayMessage = buildErrorDisplayMessage(error);
      throw rejectWithValue(errorDisplayMessage);
    }
  }
);
