import { initialState } from './state';
import { createSlice } from '@reduxjs/toolkit'
import { getMetadata } from './thunks/getMetadata';
import { submitForm } from './thunks/submitForm';
import { DocumentRequestFormConstants } from '../constants';
import { addMetadata, failMetadata, pendingMetadata } from './reducers/metadataReducer';
import { completeFormSubmission, pendingFormSubmission, failFormSubmission } from './reducers/formReducer';

const slice = createSlice({
    name: DocumentRequestFormConstants.StoreSliceName,
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.formData = { ...state.formData, ...action.payload }
        },
        setPatientDOB: (state, action) => {
            state.formData.patientDob = action.payload
        },
        setPatientSSN: (state, action) =>{
            state.formData.patientSsn = action.payload
        },
        setStates: (state, action) =>{
            state.error.formData = null
            state.success.formData = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMetadata.pending, pendingMetadata)
            .addCase(getMetadata.fulfilled, addMetadata)
            .addCase(getMetadata.rejected, failMetadata)
            .addCase(submitForm.pending, pendingFormSubmission)
            .addCase(submitForm.fulfilled, completeFormSubmission)
            .addCase(submitForm.rejected, failFormSubmission)
    }
});

export { getMetadata, submitForm };
export const { setFormData, setPatientDOB, setStates, setPatientSSN } = slice.actions;
export default slice.reducer;