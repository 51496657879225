import { logDebug, logError } from "../../../../../services/telemetry/logger";
import { DocumentRequestFormConstants } from "views/documentsRequest/constants";

const userData = JSON.parse(localStorage.getItem('userData'))

let isItemAdded = false;
export const addInvoiceInfo_r = (state, action) => {
  const invoiceItemFeeType = action.payload.invoiceItemFeeType;
  
  logDebug(`payload: ${JSON.stringify(action.payload)}`, "EditInvoiceInfo");

  const documentRequestInvoiceSummaryApiPayload = state.apiPayload.documentRequestInvoiceDetails;
  const documentRequestInvoiceDataApiPayload = state.apiPayload.documentRequestUpdatedInvoiceData;

  const feeTypes = state.metadata.feeTypes.find(
    (type) => type?.id === invoiceItemFeeType
  );

  if (feeTypes == null) {
    logError(
      "Invalid Fee Type data - Invoice Item data cannot be added",
      "RequestInvoiceInfoReducer"
    );
    return;
  }

  const duplicateItem = state.invoice.invoiceItems.find(
    (item) => item.feeDescription?.id === invoiceItemFeeType
  );  

  if(duplicateItem){
    logError(
      "Duplicate Fee Type data - Invoice Item data cannot be added",
      "RequestInvoiceInfoReducer"
    );
    return;
  }

  // Creating new Invoice item object for show in the UI
  const newInvoiceItem = {
    id: _generateRandomUniqueId(1),
    serviceFeeId: action.payload.invoiceItemFeeType,
    unitPrice: action.payload.unitPrice,
    quantity: action.payload.quantity,
    subTotal: action.payload.subTotal,
    tax: action.payload.taxAmount,
    Units:feeTypes.Units,
    UnitType:feeTypes.UnitType,
    updatedBy: `${userData && userData.firstName} ${userData && userData.lastName}`,
    updatedAt: _getFormattedCurrentDate(),
    feeDescription: {
      id: feeTypes.id,
      description: feeTypes.description,
    },
    description: action.payload.description,
    serviceId: action.payload.serviceId
  };

  logDebug(
    `RequestInvoiceInfoReducer: Adding new Service Fee : ${JSON.stringify(
      newInvoiceItem
    )}`,
    "RequestInvoiceInfoReducer"
  );

  const updatedInvoiceDetailsApiPayload = {
    ...documentRequestInvoiceSummaryApiPayload,
    _action: documentRequestInvoiceDataApiPayload.some(
      (record) => record._action === "UPDATE" || record._action === "DELETE"
    )
      ? "UPDATE"
      : "ADD",
  };

  state.apiPayload.documentRequestInvoiceDetails = updatedInvoiceDetailsApiPayload;

  state.invoice.invoiceItems.push(newInvoiceItem);

  //updating API PAYLOAD
  if (state.apiPayload.documentRequestUpdatedInvoiceData == null) {
    state.apiPayload.documentRequestUpdatedInvoiceData = [];
  }
  state.apiPayload.documentRequestUpdatedInvoiceData.push({
    ...newInvoiceItem,
    _action: DocumentRequestFormConstants.ApiActions.Add,
  });
  state.modifications.requestInvoice = true;
  isItemAdded = true;
};

export const editInvoiceInfo_r = (state, action) => {
  logDebug(`payload: ${JSON.stringify(action.payload)}`, "editInvoiceInfo_r");

  const documentRequestInvoiceSummaryApiPayload = state.apiPayload.documentRequestInvoiceDetails;

  const {
    invoiceItemFeeType,
    quantity,
    subTotal,
    unitPrice,
    isTaxed,
    taxAmount,
    description,
    invoiceId,
  } = action.payload;

  const feeTypes = state.metadata.feeTypes.find(
    (type) => type.id === invoiceItemFeeType
  );

  if (feeTypes == null) {
    logError(
      "Invalid Fee Types, cannot be added to Invoice Item",
      "RequestInvoiceInfoReducer"
    );
    return;
  }

  const invoiceItemId = state.ui.editableInvoiceInfoId; 
  var invoiceItemEditIndex = state.invoice.invoiceItems.findIndex(
    (item) => item.id === invoiceItemId
  );

  if (invoiceItemEditIndex == null || invoiceItemEditIndex < 0) {
    logError(
      "Invalid Fee Type, Cannot find item to edit",
      "RequestInvoiceInfoReducer"
    );
    return;
  } else {
    // invoiceItemEditIndex = invoiceItemEditIndex - 1;
  }
  logDebug(
    `Editing Invoice Item  index: ${invoiceItemEditIndex}`,
    "RequestInvoiceInfoReducer"
  );


  // TODO
  const updateInvoiceItem = {
    id: invoiceItemId,
    serviceFeeId: feeTypes.id,
    unitPrice: unitPrice,
    quantity: quantity,
    subTotal: subTotal,
    description: description,
    invoiceId: invoiceId,
    tax: taxAmount,
    feeDescription: {
      id: feeTypes.id,
      description: feeTypes.description,
    },
    serviceId: action.payload.serviceId
  };

  const updatedInvoiceHistoryApiPayload = {
    ...updateInvoiceItem,
    _action: invoiceId?DocumentRequestFormConstants.ApiActions.Update:DocumentRequestFormConstants.ApiActions.Add,
  };
  
  const statusHistoryApiPayloadIndex =
    state.apiPayload.documentRequestUpdatedInvoiceData.findIndex(
      (invoiceHistory) => invoiceHistory.id === invoiceItemId
    );

  if (statusHistoryApiPayloadIndex >= 0) {
    state.apiPayload.documentRequestUpdatedInvoiceData[
      statusHistoryApiPayloadIndex
    ] = updatedInvoiceHistoryApiPayload;
  } else {
    state.apiPayload.documentRequestUpdatedInvoiceData.push(
      updatedInvoiceHistoryApiPayload
    );
  }

  const updatedInvoiceDetailsApiPayload = {
    ...documentRequestInvoiceSummaryApiPayload,
    _action: DocumentRequestFormConstants.ApiActions.Update,
  };

  state.apiPayload.documentRequestInvoiceDetails = updatedInvoiceDetailsApiPayload;
  state.invoice.invoiceItems[invoiceItemEditIndex] = updateInvoiceItem;
  state.modifications.requestInvoice = true;
};

export const delInvoiceInfo_r = (state, action) => {
  const { deleteInvoiceItemId } = action.payload;

  var documentRequestInvoiceSummaryApiPayload = state.apiPayload.documentRequestInvoiceDetails;
  // Assuming that state.invoice contains the invoice object
  const itemIndexToDelete = state.invoice.invoiceItems.findIndex(
    (entry) => entry.id === deleteInvoiceItemId
  );


  const itemIndexToApiPayload =
    state.apiPayload.documentRequestUpdatedInvoiceData.findIndex(
      (entry) => entry.id === deleteInvoiceItemId
    );

  //Finding if the data is already exist or not
  const existingItem = state.invoice.invoiceItems.find(
    (e) => e.id === deleteInvoiceItemId
  );

  // Checking if the status history entity has already been added to the payload (edited or added in the same session)
  if (itemIndexToApiPayload >= 0) {
    if (
      state.apiPayload.documentRequestUpdatedInvoiceData[itemIndexToApiPayload]
        ._action === DocumentRequestFormConstants.ApiActions.Add
    ) {
      const newlyAddedStatusHistoryId =
        state.apiPayload.documentRequestUpdatedInvoiceData[
          itemIndexToApiPayload
        ].id;
      state.apiPayload.documentRequestUpdatedInvoiceData =
        state.apiPayload.documentRequestUpdatedInvoiceData.filter(
          (statusHistory) => statusHistory.id !== newlyAddedStatusHistoryId
        );
    } else if (
      existingItem.id ===
        state.apiPayload.documentRequestUpdatedInvoiceData[
          itemIndexToApiPayload
        ].id &&
      state.apiPayload.documentRequestUpdatedInvoiceData[itemIndexToApiPayload]
        ._action === DocumentRequestFormConstants.ApiActions.Update
    ) {
      const newlyAddedStatusHistoryId =
        state.apiPayload.documentRequestUpdatedInvoiceData[
          itemIndexToApiPayload
        ].id;
      state.apiPayload.documentRequestUpdatedInvoiceData =
        state.apiPayload.documentRequestUpdatedInvoiceData.filter(
          (statusHistory) => statusHistory.id !== newlyAddedStatusHistoryId
        );

      if (isItemAdded === false) {
        state.apiPayload.documentRequestUpdatedInvoiceData.push({
          id: deleteInvoiceItemId,
          _action: DocumentRequestFormConstants.ApiActions.Delete,
        });
      }
    }
  } else {
    state.apiPayload.documentRequestUpdatedInvoiceData.push({
      id: deleteInvoiceItemId,
      _action: DocumentRequestFormConstants.ApiActions.Delete,
    });
  }

  documentRequestInvoiceSummaryApiPayload = state.apiPayload.documentRequestInvoiceDetails;

  const updatedInvoiceDetailsApiPayload = {
    ...documentRequestInvoiceSummaryApiPayload,
    _action: DocumentRequestFormConstants.ApiActions.Delete,
  };

  state.apiPayload.documentRequestInvoiceDetails = updatedInvoiceDetailsApiPayload;

  if(itemIndexToDelete >=0){
    state.invoice.invoiceItems.splice(itemIndexToDelete, 1); // Removes 1 item at the specified index
    state.modifications.requestInvoice = true;
  }

};

const _generateRandomUniqueId = (documentRequestStatusHistory) => {
  const maxId =
    documentRequestStatusHistory && documentRequestStatusHistory.length
      ? Math.max(...documentRequestStatusHistory.map((entry) => entry.id))
      : 0;
  const randomNumber = Number.parseInt(Math.random() * 100, 10);
  logDebug(
    `Generating random document request status history maxId: ${maxId} | randomNumber - ${randomNumber}`
  );
  return maxId + randomNumber;
};

const _getFormattedCurrentDate = () => {
  const now = new Date();
  return `${now.getUTCFullYear()}-${now.getUTCMonth()}-${now.getUTCDate()}T${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}.${now.getUTCMilliseconds()}Z`;
};

// handling get invoice items API
export const getRequestInvoiceDetailsPending = (state, action) => {
  state.errors.invoice = null;
  state.invoice.invoiceItems = [];
  state.loading.invoice = true;
};

export const getRequestInvoiceDetailsComplete = (state, action) => {
  state.errors.invoice = null;
  state.invoice.invoiceItems = action.payload;
  state.loading.invoice = false;
};

export const getRequestInvoiceDetailsFailure = (state, action) => {
  state.invoice.invoiceItems = [];
  state.errors.invoice = action.payload;
  state.loading.invoice = false;
};

// handling get invoice items by service API
export const getInvoiceDetailsByServicePending = (state, action) => {
  state.errors.invoiceServices = null;
  state.invoiceServices = [];
  state.loading.invoiceServices = true;
  state.hasInvoiceServicesUpdated = false;
};

export const getInvoiceDetailsByServiceComplete = (state, action) => {
  state.errors.invoiceServices = null;
  state.invoiceServices = action.payload;
  state.loading.invoiceServices = false;
  state.hasInvoiceServicesUpdated = true;
};

export const getInvoiceDetailsByServiceFailure = (state, action) => {
  state.errors.invoiceServices = [];
  state.invoiceServices = [];
  state.loading.invoiceServices = false;
  state.hasInvoiceServicesUpdated = false;
};

// handling get invoice items by service API
export const getUniversalServiceFeesForInvoicePending = (state, action) => {
  state.errors.universalInvoiceServices = null;
  state.universalInvoiceServices = [];
  state.loading.universalInvoiceServices = true;
  state.hasUniversalInvoiceServicesUpdated = false;
};

export const getUniversalServiceFeesForInvoiceComplete = (state, action) => {
  state.errors.universalInvoiceServices = null;
  state.universalInvoiceServices = action.payload;
  state.loading.universalInvoiceServices = false;
  state.hasUniversalInvoiceServicesUpdated = true;
};

export const getUniversalServiceFeesForInvoiceFailure = (state, action) => {
  state.invoice.universalInvoiceServices = [];
  state.universalInvoiceServices = action.payload;
  state.loading.universalInvoiceServices = false;
  state.hasUniversalInvoiceServicesUpdated = false;
};

// handling get invoice info API
export const getRequestInvoiceInfoPending = (state, action) => {
  state.errors.invoiceInfo = null;
  state.invoiceInfo = [];
  state.loading.invoiceInfo = true;
};

export const getRequestInvoiceInfoComplete = (state, action) => {
  state.errors.invoiceInfo = null;
  state.invoiceInfo = action.payload;
  state.loading.invoiceInfo = false;
};

export const getRequestInvoiceInfoFailure = (state, action) => {
  state.invoiceInfo = [];
  state.errors.invoiceInfo = action.payload;
  state.loading.invoiceInfo = false;
};

// handling get paymeny history API
export const getPaymentHistoryPending = (state, action) => {
  state.errors.paymentHistory = null;
  state.paymentHistory = [];
  state.loading.paymentHistory = true;
};

export const getPaymentHistoryComplete = (state, action) => {
  state.errors.paymentHistory = null;
  state.paymentHistory = action.payload;
  state.loading.paymentHistory = false;
};

export const getPaymentHistoryFailure = (state, action) => {
  state.paymentHistory = [];
  state.errors.paymentHistory = action.payload;
  state.loading.paymentHistory = false;
};

// handling update invoice items API
export const updateInvoiceItemsPending = (state, action) => {
  state.errors.updateInvoice = null;
  state.loading.updateInvoice = true;
};

export const updateInvoiceItemsFailure = (state, action) => {
  state.errors.updateInvoice = action.payload;
  state.loading.updateInvoice = false;
};

export const updateInvoiceItemsComplete = (state, action) => {
  state.apiPayload.documentRequestUpdatedInvoiceData = [];
  state.errors.updateInvoice = null;
  state.loading.updateInvoice = false;
  state.modifications.requestInvoice = false;
};

// handling fee types API
export const getFeeTypesPending = (state, action) => {
  state.errors.metadata.feeTypes = null;
  state.loading.metadata.feeTypes = true;
};

export const getFeeTypesFailure = (state, action) => {
  state.errors.metadata.feeTypes = null
  state.loading.metadata.feeTypes = false;
};

export const getFeeTypesComplete = (state, action) => {
  state.metadata.feeTypes = action.payload;
  state.errors.metadata.feeTypes = null;
  state.loading.metadata.feeTypes = false;
};

export const createCheckPaymentPending = (state, action) => {
  state.errors.createCheckPayment = null;
  state.loading.createCheckPayment = true;
};

export const createCheckPaymentFailure = (state, action) => {
  state.errors.createCheckPayment = action.payload;
  state.loading.createCheckPayment = false;
};

export const createCheckPaymentComplete = (state, action) => {
  state.errors.createCheckPayment = null;
  state.loading.createCheckPayment= false
  state.serverResponse.createCheckPayment = action.payload
};

export const writeOffPaymentPending = (state, action) => {
  state.errors.createWriteOffPayment = null;
  state.loading.createWriteOffPayment = true;
};

export const writeOffPaymentFailure = (state, action) => {
  state.errors.createWriteOffPayment = action.payload;
  state.loading.createWriteOffPayment = false;
};

export const writeOffPaymentComplete = (state, action) => {
  state.errors.createWriteOffPayment = null;
  state.loading.createWriteOffPayment= false
  state.serverResponse.createWriteOffPayment = action.payload
};

export const generateInvoicePending = (state, action) => {
  state.errors.generateInvoice = null;
  state.loading.generateInvoice = true;
};

export const generateInvoiceFailure = (state, action) => {
  state.errors.generateInvoice = action.payload;
  state.loading.generateInvoice = false;
};

export const generateInvoiceComplete = (state, action) => {
  state.errors.generateInvoice = null;
  state.loading.generateInvoice= false
  state.serverResponse.generateInvoice = action.payload
};