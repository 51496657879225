import { DocumentRequestFormConstants } from "views/documentsRequest/constants";
import { logDebug, logError } from "../../../../../services/telemetry/logger";

//functions

const _getFormattedCurrentDate = () => {
  const now = new Date();
  return `${now.getUTCFullYear()}-${now.getUTCMonth()}-${now.getUTCDate()}T${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}.${now.getUTCMilliseconds()}Z`;
};

const _generateRandomUniqueId = (documentRequestStatusHistory) => {
  const maxId =
    documentRequestStatusHistory && documentRequestStatusHistory.length
      ? Math.max(...documentRequestStatusHistory.map((entry) => entry.id))
      : 0;
  const randomNumber = Number.parseInt(Math.random() * 100, 10);
  logDebug(
    `Generating random document request status history maxId: ${maxId} | randomNumber - ${randomNumber}`
  );
  return maxId + randomNumber;
};

//reducers

// xray body parts masterdata

export const getXrayBodyPartsPending = (state, action) => {
  console.debug('xray body parts Reducer Pending', action);
  state.errors.xrayBodyParts = null;
  state.metadata.xrayBodyParts = {};
  state.loading.metadata.xrayBodyParts = true;
};

export const getXrayBodyPartsComplete = (state, action) => {
  console.debug('xray body parts Reducer Complete', action);
  state.errors.xrayBodyParts = null;
  state.metadata.xrayBodyParts = action.payload;
  state.loading.metadata.xrayBodyParts = false;
};

export const getXrayBodyPartsFailure = (state, action) => {
  console.debug('xray body parts Reducer Failure', action);
  state.errors.xrayBodyParts = action.payload;
  state.loading.metadata.xrayBodyParts = false;
};

//list
export const getDocumentRequestXrayBreakdownPending = (state, action) => {
  console.debug("Document Request Xray Breakdown Reducer Pending", action);
  state.errors.xRayBreakdown = null;
  if (state.documentRequest != null) {
    state.documentRequest.xRayBreakdown = [];
  }
  state.loading.statusHistory = true;
};

export const getDocumentRequestXrayBreakdownComplete = (state, action) => {
  console.debug("Document Request Xray Breakdown Reducer Complete", action);
  state.errors.xRayBreakdown = null;
  if (state.documentRequest == null) {
    state.documentRequest = {};
  }
  state.documentRequest.xRayBreakdown = action.payload;
  state.loading.xRayBreakdown = false;
};

export const getDocumentRequestXrayBreakdownFailure = (state, action) => {
  console.debug("Document Request Xray Breakdown Reducer Failure", action);
  state.errors.xRayBreakdown = action.payload;
  state.loading.xRayBreakdown = false;
};

export const deleteRequestXrayBreakdown = (state, action) => {
  const { documentRequestXrayBreakdownId, row } = action.payload;
  const removedObjIndex = state.documentRequest.xRayBreakdown.findIndex(
    (entry) => {
      const idToBeCompared = entry.id ?? entry.bodyPartId;
      return idToBeCompared == documentRequestXrayBreakdownId && entry.quantity == row.quantity;
    }
      )
  const removedObj = state.documentRequest.xRayBreakdown.splice(removedObjIndex,1)
  // removing from state
  state.documentRequest = {
    ...state.documentRequest,
    // xRayBreakdown: removedObj
  };
  const xRayBreakdownApiPayloadIndex =
    state.apiPayload.documentRequestXrayBreakdown.findIndex(
      (xRayBreakdown) => xRayBreakdown.id === documentRequestXrayBreakdownId
    );
  // Checking if the status history entity has already been added to the payload (edited or added in the same session)
  if (xRayBreakdownApiPayloadIndex >= 0) {
        // The entry has been added in this session, so deletion means simply removal of the entry from the payload
    if (
      state.apiPayload.documentRequestXrayBreakdown[
        xRayBreakdownApiPayloadIndex
      ]._action === DocumentRequestFormConstants.ApiActions.Add
    ) {
            const newlyAddedXrayBreakdownId =
        state.apiPayload.documentRequestXrayBreakdown[
          xRayBreakdownApiPayloadIndex
        ].id;
      state.apiPayload.documentRequestXrayBreakdown =
        state.apiPayload.documentRequestXrayBreakdown.filter(
          (xRayBreakdown) => xRayBreakdown.id !== newlyAddedXrayBreakdownId
        );
    } else {
      state.apiPayload.documentRequestXrayBreakdown[
        xRayBreakdownApiPayloadIndex
      ]._action = DocumentRequestFormConstants.ApiActions.Delete;
    }
  }  else {
    
    if(row.id){
      state.apiPayload.documentRequestXrayBreakdown.push({
        ...row,
         _action: DocumentRequestFormConstants.ApiActions.Delete,
       });
    }
    else{
      state.apiPayload.documentRequestXrayBreakdown.splice(removedObjIndex,1)
    }
   
  }
  state.modifications.xRayBreakdown = true;
};

//xray reset reducer

export const resetXrayBreakdownApiPayloadReducer = (state, _) => {
  console.debug("Reset Xray Breakdown Api Payload");
  state.apiPayload.documentRequestXrayBreakdown = [];
};

// edit reducer

export const editRequestXrayBreakdownReducer = (state, action) => {
  const { paymentStatus, notes } = action.payload;

  const requestXrayPayment = state.metadata.requestXrayPayment.find(
    (status) => status.id === paymentStatus
  );

  //error checks
  if (requestXrayPayment == null) {
    logError(
      "Invalid request payment status cannot be added to xray payment status",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  if (notes == null) {
    logError(
      "Invalid notes cannot be added to xray breakdown notes",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  //
  const documentRequestXrayBreakdownId =
    state.ui.editableDocumentRequestXrayBreakdownId;
  const xRayBreakdownEntryIndex = state.documentRequest.xRayBreakdown.findIndex(
    (xRay) => xRay.bodyPartId === documentRequestXrayBreakdownId 
  );
  if (xRayBreakdownEntryIndex == null || xRayBreakdownEntryIndex < 0) {
    logError(
      "Invalid xray breakdown data cannot be added to xray breakdown data",
      "RequestXrayBreakdownReducer"
    );
    return;
  }
  logDebug(
    `Editing status history index: ${xRayBreakdownEntryIndex}`,
    "RequestXrayBreakdownReducer"
  );

  const updatedXrayBreakdownEntry = {
    ...state.documentRequest.xRayBreakdown[xRayBreakdownEntryIndex],
    documentRequestOrderId: state.documentRequest.id,
    paymentStatus: requestXrayPayment.label,
    updatedBy: "UI_USER", // This name must come from state, when user is logged in
    updatedAt: _getFormattedCurrentDate(),
    notes: notes,
    // ... ...
  };
  state.documentRequest.xRayBreakdown[xRayBreakdownEntryIndex] =
    updatedXrayBreakdownEntry;
  if (state.apiPayload.documentRequestXrayBreakdown == null) {
    state.apiPayload.documentRequestXrayBreakdown = [];
  }

  // Add the entry in API payload
  const updatedXrayBreakdownApiPayloadEntity = {
    ...updatedXrayBreakdownEntry,
    _action: DocumentRequestFormConstants.ApiActions.Update,
  };
  const xRayBreakdownApiPayloadIndex =
    state.apiPayload.documentRequestXrayBreakdown.findIndex(
      (xray) => xray.id === documentRequestXrayBreakdownId
    );
  // Checking if the status history entity has already been addded to the payload (edited or added in the same session)
  if (xRayBreakdownApiPayloadIndex >= 0) {
    state.apiPayload.documentRequestXrayBreakdown[
      xRayBreakdownApiPayloadIndex
    ] = updatedXrayBreakdownApiPayloadEntity;
  } else {
    state.apiPayload.documentRequestXrayBreakdown.push(
      updatedXrayBreakdownApiPayloadEntity
    );
  }
  state.modifications.xRayBreakdown = true;
};

// add reducer

export const addRequestXrayBreakdownReducer = (state, action) => {
  const { bodyPart, quantity, examDate, ppf, tax } = action.payload;

  //error checks
  if (bodyPart == null) {
    logError(
      "Invalid payment status cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  if (quantity == null) {
    logError(
      "Invalid notes cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }
  
  if (examDate == null) {
    logError(
      "Invalid Exam Date cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  if (ppf == null) {
    logError(
      "Invalid ppf cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  if (tax == null) {
    logError(
      "Invalid tax amount cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  const newXrayBreakdownEntry = {
    // id: _generateRandomUniqueId(state.documentRequest.xRayBreakdown),
    bodyPartId: bodyPart,
    quantity: quantity,
    examDate: examDate,
    unitPrice: ppf,
    taxAmount: tax,
  };

  logDebug(
    `RequestXrayBreakdownReducer: Adding new XrayBreakdown Entry: ${JSON.stringify(
      newXrayBreakdownEntry
    )}`,
    "RequestXrayBreakdownReducer"
  );

  state.documentRequest = {
    ...state.documentRequest,
    xRayBreakdown: [
      ...state.documentRequest.xRayBreakdown,
      newXrayBreakdownEntry,
    ],
  };

  // Add the entry in API payload
  if (state.apiPayload.documentRequestXrayBreakdown == null) {
    state.apiPayload.documentRequestXrayBreakdown = [];
  }
  state.apiPayload.documentRequestXrayBreakdown.push({
    ...newXrayBreakdownEntry,
    _action: DocumentRequestFormConstants.ApiActions.Add,
  });
  state.modifications.xRayBreakdown = true;
};

export const editRequestXrayItemReducer = (state, action) => {
  const { bodyPart, quantity, examDate, ppf, tax } = action.payload;

  //error checks
  if (bodyPart == null) {
    logError(
      "Invalid payment status cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  if (quantity == null) {
    logError(
      "Invalid notes cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }
  
  if (examDate == null) {
    logError(
      "Invalid Exam Date cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  if (ppf == null) {
    logError(
      "Invalid ppf cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  if (tax == null) {
    logError(
      "Invalid tax amount cannot be added to XrayBreakdown",
      "RequestXrayBreakdownReducer"
    );
    return;
  }

  const documentRequestXrayBreakdownId = state.ui.editableDocumentRequestXrayBreakdownId;
  const xRayBreakdownEntryIndex = state.documentRequest.xRayBreakdown.findIndex(
    (xRay) => xRay.bodyPartId === documentRequestXrayBreakdownId.bodyPartId && xRay.examDate===documentRequestXrayBreakdownId.examDate
  );
  if (xRayBreakdownEntryIndex == null || xRayBreakdownEntryIndex < 0) {
    logError(
      "Invalid xray breakdown data cannot be added to xray breakdown data",
      "RequestXrayBreakdownReducer"
    );
    return;
  }
  logDebug(
    `Editing status history index: ${xRayBreakdownEntryIndex}`,
    "RequestXrayBreakdownReducer"
  );

  const updatedXrayBreakdownEntry = {
    ...state.documentRequest.xRayBreakdown[xRayBreakdownEntryIndex],
    documentRequestOrderId: state.documentRequest.id,
    updatedBy: "UI_USER", // This name must come from state, when user is logged in
    updatedAt: _getFormattedCurrentDate(),
    bodyPartId: bodyPart,
    quantity: quantity,
    examDate: examDate,
    unitPrice: ppf,
    taxAmount: tax,
  };
  state.documentRequest.xRayBreakdown[xRayBreakdownEntryIndex] =
    updatedXrayBreakdownEntry;
  // Find the existing entry in apiPayload for the same xRayBreakdownEntryIndex
  const existingApiPayloadEntry = state.apiPayload.documentRequestXrayBreakdown.find(
    (entry) => entry.xRayBreakdownEntryIndex === xRayBreakdownEntryIndex
  );
  
  // If an existing entry is found, update it with the new data, otherwise add a new entry
  if (existingApiPayloadEntry) {
    // Update the existing entry with the new data
    Object.assign(existingApiPayloadEntry, {
      ...updatedXrayBreakdownEntry,
      _action: DocumentRequestFormConstants.ApiActions.Update,
    });
  } else {
    // check if already exists with bodypart ID
    if (!updatedXrayBreakdownEntry.id) {
      // it's an update of just added record which is not saved
      state.apiPayload.documentRequestXrayBreakdown = state.apiPayload.documentRequestXrayBreakdown.map(
        doc => {
          if (!doc.id && doc.bodyPartId == documentRequestXrayBreakdownId.bodyPartId && doc.examDate==documentRequestXrayBreakdownId.examDate) {
            return { ...doc, ...updatedXrayBreakdownEntry }
          }
          return doc;
        }

      )
    } else {
      // Add a new entry for the xRayBreakdownEntryIndex
      state.apiPayload.documentRequestXrayBreakdown.push({
        ...updatedXrayBreakdownEntry,
        _action: DocumentRequestFormConstants.ApiActions.Update,
        xRayBreakdownEntryIndex: xRayBreakdownEntryIndex,
      });
    }
  }

  state.modifications.xRayBreakdown = true;
};