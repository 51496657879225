export const buildErrorDisplayMessage = (error) => {
    if (error == null) {
        return 'Oops! Some unhandled error ocurred. Please contact support';
    }
    if (error.name && error.name === 'AxiosError') {
        if (error.response) {
            if (error.response.statusText && error.response.statusText !== '') {
                return error.response.statusText;
            }
            if (error.response.data && error.response.data !== '') {
                return error.response.data;
            }
            return 'Oops! Error ocurred in calling API. Please contact support.'
        }
    }
    if (error.message) {
        return error.message;
    }
    return 'Oops! Some unhandled error ocurred. Please contact support';
}
