import { createAsyncThunk } from '@reduxjs/toolkit';
import {ProfileInformationConstant} from '../../constants'
import AdminRequestService from '../../../../../services/admin/adminRequestService';
import { buildErrorDisplayMessage } from '../../../../../utils/errorMessageBuilder';
import PatientRequestService from 'services/patient/patientRequestService';

export const getUserInformation = createAsyncThunk(`${ProfileInformationConstant.StoreSliceName}/getUserInformation`, async (payload, { rejectWithValue }) => {
    try {
        console.debug('Get Document Request Details Thunk Payload', payload);
        return await PatientRequestService.getUserInformation(payload)
    } catch (error) {
        console.error('Failed to get admin document request', error);
        const errorDisplayMessage = buildErrorDisplayMessage(error);
        throw rejectWithValue(errorDisplayMessage);
    }
});
