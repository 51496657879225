import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentRequestFormConstants } from '../../constants';
import DocumentRequestService from '../../../../services/documentRequestService';

export const submitForm = createAsyncThunk(`${DocumentRequestFormConstants.StoreSliceName}/submitForm`, async (form, { rejectWithValue }) => {
    try {
        function formatdate(date) {
            if (!date) {
            return null;
            }
            const tempdate = new Date(date);
            const originalDateString=tempdate.toString()
            const day = originalDateString.substring(8, 10);
            const month = getMonthInNumeric(originalDateString.substring(4, 7));
            const year = originalDateString.substring(11, 15);
            const time = originalDateString.substring(16, 24);
            const formattedDate = `${year}-${month}-${day}T${time}Z`;
            return formattedDate;
            function getMonthInNumeric(month) {
            const months = {
            Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
            Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12',
            };
            return months[month];
            }
            }
            form.documentRequest.recordsFrom = form.documentRequest.recordsFrom.map((date) =>(date ? formatdate(date) : null));
            form.documentRequest.recordsTo = form.documentRequest.recordsTo.map((date) => (date ? formatdate(date) : null));
        const {documentRequest, resetForm} = form
        const response = await DocumentRequestService.submitDocumentRequestForm(documentRequest);
        if(response?.status === 200) {
            resetForm()
        }
        return response
    } catch (error) {
        // TODO - Log the error;
        console.error('Error in submitForm Thunk', error);
        return rejectWithValue(error.message);
    }
});
