export const initialState = {
  userList: [
    {
      id: 1,
      firstName: "",
      middleName: null,
      lastName: "",
      dob: "",
      last4SSN: "",
      phoneNumber: "",
      email: "",
      addressId: null,
      userType: "",
      attorneyCompanyId: null,
      doctorFacilityId: null,
      isEmailVerified: false,
      updatedBy: "",
      updatedAt: "",
      address: {
        id: 1,
        line1: "",
        line2: "",
        city: "",
        state: "",
        zipcode: "",
        updatedBy: "",
        updatedAt: "",
      },
    },
  ],
  loading: {
    getAllUsersList: false
  },
  error: {
    getAllUsersList: null
  }
};
