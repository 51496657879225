export const getOrderListPending = (state, action) => {
    console.debug('Get Order List Reducer Pending', action);
    state.errors.patient = null;
    state.orderListData = [];
    // state.orderSummaryData = [];
    state.loading.patient = true;
};

export const getOrderListComplete = (state, action) => {
    console.debug('Get Order List Reducer Complete', action);
    const data = action?.payload
    const documentRequest = data?.documentRequest;
    const sortedData = [...documentRequest].sort((a,b)=> b?.DocumentRequestOrderId - a?.DocumentRequestOrderId)
    state.errors.patient = null;
    state.orderListData = sortedData;
    state.loading.patient = false;
};

export const getOrderListFailure = (state, action) => {
    console.debug('Get Order List Reducer Failure', action);
    state.errors.patient = action.payload;
    state.loading.patient = false;
};
