export const updateCompanyDetailsPending = (state, action) => {
    console.debug('Update company details pending', action);
    state.error.updateCompanyDetails = null;
    state.loading.updateCompanyDetails = true;
    state.serverResponse = null;
};

export const updateUserCompanyComplete = (state, action) => {
    console.debug('Update company details completed', action.payload);
    state.error.updateCompanyDetails = null;
    state.loading.updateCompanyDetails = false;
    state.serverResponse = action.payload;
};

export const updateCompanyDetailsFailure = (state, action) => {
    console.debug('Update company details pending Failure', action.payload);
    state.error.updateCompanyDetails = action.payload;
    state.loading.updateCompanyDetails = false;
    state.serverResponse = action.payload;
};
