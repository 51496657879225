export const AdminEditRequestConstants = {
    StoreSliceName: 'AdminEditRequest'
};

export const InternalToOrderStatusMap = {
    1: 1,    
    2: 2,
    4: 2,
    5: 2,
    6: 2,
    7: 2,
    9: 2,
    10: 2,
    11: 2,
    12: 2,
    3: 3,
    8: 4   
  };

  export const XRAY ='X-Ray'